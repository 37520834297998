import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { changeDescriptionOfTask } from "../../services/tasks/ChangeDescriptionOfTask";
import Swal from "sweetalert2";

const TaskEditModal = ({ show, handleClose, taskId, previousDescription }) => {
  const [description, setDescription] = useState(previousDescription);

  const handleSave = async () => {
    try {
      const data = {
        task_id: taskId,
        description: description,
      };
      const response = await changeDescriptionOfTask(data);
      if (response.status == 200) {
        Swal.fire({
          title: response.data.message,
          timer: 1500,
          icon: "success",
          showConfirmButton: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        Swal.fire({
          title: "Error!",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="taskDescription">
            <Form.Label style={{ fontWeight: "600", marginBottom: "10px" }}>Task Description</Form.Label>
            <textarea
              name="description"
              id=""
              maxLength="450"
              className="taskField setHeghtMessage"
              placeholder="Describe what you need help with"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{
                width: "100%",
                height: "120px",
                padding: "12px",
                fontSize: "16px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                resize: "none", // Prevent resizing
              }}
            ></textarea>
            <div style={{ textAlign: "right", fontSize: "12px", color: "#888" }}>{450 - description.length} characters remaining</div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="saveButton" variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button className="primaryImportant" variant="secondary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskEditModal;
