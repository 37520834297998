import React, { useEffect, useState } from "react";
import { Col, Container, Row, Pagination } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import AdvacnceSearchBar from "../advancesearchbar/AdvacnceSearchBar";
import { useSingleOrderDraftInfo } from "../hooks/getSingleOrderDraft";
import StudentInviteCard from "./customerinvitecards/StudentInviteCard";
import Loader from "../loader/Loader";
import { ShowAllStudentsForSearch } from "../../services/users/ShowAllStudentsForSearch";

const CustomerInviteStudents = () => {
  let inviteCount = 0;
  const student_role = 2;
  const { orderId } = useParams();
  const currentURL = window.location.href;
  const urlParts = currentURL.split("/");
  const task_id = urlParts[urlParts.length - 2];
  const singleOrder = useSingleOrderDraftInfo(parseInt(task_id));
  const [searchParams, setSearchParams] = useSearchParams(); // For query parameters
  const [loader, setLoader] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [seed, setSeed] = useState(null);
  const [searchedValue, setSearchedValue] = useState();
  const [displayedStudents, setDisplayedStudents] = useState([]);
  const currentPage = parseInt(searchParams.get("page")) || 1;
  const client_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;

  useEffect(() => {
    const showModal = localStorage.getItem("showModal");

    if (showModal === "true") {
      Swal.fire({
        title: "Invite multiple students for your task. You'll receive a notification once a student accepts your task.",
        showConfirmButton: true,
        customClass: "stModal stModalinvite",
      });
    }

    localStorage.removeItem("showModal");
  }, []);

  useEffect(() => {
    const queryParams = { ...Object.fromEntries([...searchParams]) };

    // Determine if filters are applied
    const filterParams = { ...queryParams };
    delete filterParams.page; // Exclude 'page' from filters

    const filtersApplied = Object.keys(filterParams).length > 0;

    if (filtersApplied) {
      // Filters are applied, clear localStorage and fetch new data
      localStorage.removeItem("inviteStudentsData");
      getAllStudentFunc(queryParams);
    } else {
      // No filters applied, proceed with caching logic
      const savedData = localStorage.getItem("inviteStudentsData");

      if (savedData) {
        const { students, queryParams: savedQueryParams, seed: savedSeed, totalPages: savedTotalPages } = JSON.parse(savedData);
        const paramsAreEqual = JSON.stringify(savedQueryParams) === JSON.stringify(queryParams);

        if (paramsAreEqual) {
          // Use cached data
          setDisplayedStudents(students);
          setSeed(savedSeed);
          setTotalPages(savedTotalPages);
          setLoader(false);
        } else {
          // Fetch new data
          getAllStudentFunc(queryParams);
        }
      } else {
        // Fetch new data
        getAllStudentFunc(queryParams);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    // Handler to remove inviteStudentsData from localStorage
    const handlePageHide = () => {
      localStorage.removeItem("inviteStudentsData"); // Remove on page hide
    };

    window.addEventListener("pagehide", handlePageHide);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, []);

  const getAllStudentFunc = async (queryParams) => {
    setLoader(true);

    const paramsWithSeed = { ...queryParams };
    if (seed) {
      paramsWithSeed.seed = seed;
    }

    // Include additional parameters needed for inviting students
    paramsWithSeed.client_id = client_id;
    paramsWithSeed.order_id = parseInt(orderId);
    paramsWithSeed.task_id = parseInt(task_id);

    try {
      const response = await ShowAllStudentsForSearch(paramsWithSeed);
      if (response.status === 200) {
        const responseData = response.data;
        const students = responseData.users.data;

        const seedFromResponse = responseData.seed;
        if (seedFromResponse && seedFromResponse !== seed) {
          setSeed(seedFromResponse);
        }

        setDisplayedStudents(students);
        setTotalPages(responseData.users.last_page);
        setLoader(false);

        // Only save to localStorage if no filters are applied
        const filterParams = { ...queryParams };
        delete filterParams.page; // Exclude 'page' from filters

        const filtersApplied = Object.keys(filterParams).length > 0;

        if (!filtersApplied) {
          // Save data to localStorage
          localStorage.setItem(
            "inviteStudentsData",
            JSON.stringify({
              students,
              queryParams,
              seed: seedFromResponse,
              totalPages: responseData.users.last_page,
            })
          );
        } else {
          if (currentPage === 1) {
            Swal.fire({
              title: "Students Found.",
              timer: 1500,
              icon: "success",
              showConfirmButton: false,
            });
          }
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message || "An error occurred",
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
      setLoader(false);
    }
  };

  const handlePageChange = (page) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: page.toString(),
    });
  };

  const paginationItems = [];
  const offset = 1;
  for (let number = 1; number <= totalPages; number++) {
    if (number === 1 || number === totalPages || (number >= currentPage - offset && number <= currentPage + offset)) {
      paginationItems.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => {
            handlePageChange(number);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          {number}
        </Pagination.Item>
      );
    } else if (paginationItems[paginationItems.length - 1]?.type !== Pagination.Ellipsis) {
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} disabled />);
    }
  }

  const bookNow = "Invite";

  const updateSeed = (newSeed) => {
    if (newSeed && newSeed !== seed) {
      setSeed(newSeed);
    }
  };

  return (
    <>
      {loader ? (
        <div className="height100vh height10vh">
          <Loader />
        </div>
      ) : (
        <div className="viewAllStudents">
          <Container className="text-center pl90">
            <h2 className="mt140">
              Search <span className="green">Students</span>
              <div style={{ position: "relative" }}>
                <span>
                  <img className="info3 info3C" src="/assets/images/newlogos/Info.svg" alt="" />
                  <span className="hoverTextForR3 hoverTextForR3C">
                    Search for students you're looking to hire by entering your location and task. The advanced search filter enables you to narrow
                    your search for more precise results. Click on the profile card of each student to see more information.
                  </span>
                </span>
              </div>
            </h2>
            <AdvacnceSearchBar searchedValue={setSearchedValue} seed={seed} updateSeed={updateSeed} orderId={orderId} fromInvite={true} />
            <Row className="mL50p viewAllStCards" id="studentInviteCard">
              {displayedStudents?.map((singleStd) => {
                if (singleStd?.email_verified_at !== null) {
                  return (
                    <Col md={4} className="mb-5" key={singleStd?.id}>
                      <StudentInviteCard
                        studentData={singleStd}
                        setAllStudent={setDisplayedStudents}
                        singleOrder={singleOrder}
                        searchedValue={searchedValue}
                      />
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
            <div className="pagination-controls d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {paginationItems}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
              </Pagination>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default CustomerInviteStudents;
