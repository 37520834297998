import { useState, useEffect } from "react";
import { showStudent } from "../../services/users/ShowSingleStudent";

export function useSingleUserInfo(id) {
  const [data, setData] = useState(null);

  const getFunc = async () => {
    if (id != "null") {
      const resp = await showStudent(id);
      if (resp.status === 200) {
        setData(resp.data.user);
        // console.log(resp.data.user)
      }
    }
    return;
  };
  useEffect(() => {
    getFunc();
  }, []);

  return data;
}
