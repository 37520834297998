import React, { useContext, useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { SearchStudent } from "../../services/users/SearchStudent";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
import AuthContext from "../../context/AuthContext";
import { isMobile } from "react-device-detect";

function HomeBanner() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { locations } = useContext(AuthContext);
  let navigate = useNavigate();
  const [randomLocation, setRandomLocation] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    const GetlocationsRamdomFunc = async () => {
      try {
        if (locations) {
          // Check if locations is a string (needs parsing) or already an array
          const parsedLocations = Array.isArray(locations) ? locations : JSON.parse(locations);
          setRandomLocation(parsedLocations.slice(0, 100)); // Set random 100
          setAllLocations(parsedLocations); // Store all locations
        }
      } catch (e) {
        console.error("Error loading locations:", e);
      }
    };

    GetlocationsRamdomFunc();
  }, [locations]);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? "#cacaca" : isSelected ? "#82D051" : isFocused ? undefined : undefined,
        color: isDisabled ? "#cacaca" : isSelected ? "#fcfcfc" : isFocused ? "#898A8D" : "#898A8D",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: "#82D051",
        },
      };
    },
    input: (styles) => ({
      ...styles,
      color: "#898A8D",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#898a8d",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#898A8D",
      fontFamily: "'Manrope', sans-serif",
      fontWeight: 400,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "999",
    }),
  };

  const onSubmit = async (fData) => {
    let dataObj = {};

    if (fData.location && fData.location.label) {
      let arrayForCityZip = fData.location.label.split(",");
      dataObj["zip"] = parseInt(arrayForCityZip[1]);
    }

    const resp = await SearchStudent(dataObj);

    if (resp.status === 200) {
      if (resp?.data?.users.length == 0) {
        Swal.fire({
          title: "No user Found.",
          timer: 1500,
          icon: "warning",
          showConfirmButton: false,
        });
      } else {
        navigate(`/signInRoute/all-students?zip=${dataObj.zip}&page=1&type=1`);
      }
    } else {
      navigate(`/signInRoute/all-students?zip=${dataObj.zip}&page=1&type=1`);
      // Swal.fire({
      //   title: resp.data.message,
      //   timer: 1500,
      //   icon: "error",
      //   showConfirmButton: false,
      // });
    }
  };

  const isiPhone = window.navigator.platform === "iPhone";

  return (
    <div className="banner center bannerMain logInClass">
      <Container>
        <Row className="columnRevers">
          <Col md={7} sm={12} className="paddingLft20 mainbannerTextPart">
            <h2>
              Hire & Support <span className="green">Local Students</span> to Manage Your <span id="dailyForMobile">Daily</span> Tasks
            </h2>
            <div className="seth6width">
              <h6>Sign up to instantly connect with thousands of local students ready to meet your needs at home or for your business.</h6>
            </div>
            <div className="bannerFields">
              <form id="homeSearchForm" className="bannerFields row" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex1 col-md-6 col-sm-12 locationSectionHome forDesktop">
                  {randomLocation.length > 0 ? (
                    <Controller
                      name="select"
                      className="border-0"
                      control={control}
                      {...register("location", { required: true })}
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          styles={colourStyles}
                          loadOptions={(inputValue, callback) => {
                            if (!inputValue) {
                              // Show a random subset of 100 locations if no input is provided
                              callback(randomLocation.slice(0, 100));
                            } else {
                              // Filter the entire list of locations based on input
                              const filteredOptions = allLocations.filter((location) => {
                                const [cityState, zip] = location?.label?.split(","); // Split into city/state and zip
                                return (
                                  cityState?.toLowerCase().startsWith(inputValue.toLowerCase()) || // Match city/state
                                  zip?.trim().startsWith(inputValue) // Match zip
                                );
                              });
                              callback(filteredOptions.slice(0, 100)); // Limit results to 100
                            }
                          }}
                          className="cityField homePageCityField border-mb-fields border-0"
                          cacheOptions
                          // defaultOptions={randomLocation.slice(0, 100)} // Show initial 100 options
                          placeholder="City or zip code"
                          menuPortalTarget={document.body}
                        />
                      )}
                    />
                  ) : (
                    <div>Loading locations...</div> // Show a loading state
                  )}
                  {errors.location && <span className="eror locationError">This field is required</span>}
                </div>
                <div className="flex2 col-md-6 col-sm-12 locationSectionHome forMobile customSearchField">
                  {randomLocation.length > 0 ? (
                    <Controller
                      name="select"
                      className="border-0"
                      control={control}
                      {...register("location", { required: true })}
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          styles={colourStyles}
                          loadOptions={(inputValue, callback) => {
                            if (!inputValue) {
                              // Show a random subset of 100 locations if no input is provided
                              callback(randomLocation.slice(0, 100));
                            } else {
                              // Filter the entire list of locations based on input
                              const filteredOptions = allLocations.filter((location) => {
                                const [cityState, zip] = location?.label?.split(","); // Split into city/state and zip
                                return (
                                  cityState?.toLowerCase().startsWith(inputValue.toLowerCase()) || // Match city/state
                                  zip?.trim().startsWith(inputValue) // Match zip
                                );
                              });
                              callback(filteredOptions.slice(0, 100)); // Limit results to 100
                            }
                          }}
                          className="cityField homePageCityField border-mb-fields border-0"
                          cacheOptions
                          // defaultOptions={randomLocation.slice(0, 100)}
                          placeholder="City or zip code"
                          menuPortalTarget={document.body}
                        />
                      )}
                    />
                  ) : (
                    <div>Loading locations...</div>
                  )}
                  <button className={`taskbutton primary center ${isMobile && "customMobileHomeBannerButton"}`}>Find Student</button>
                  {errors.location && <span className="eror locationError">This field is required</span>}
                </div>
                <div className="customSearchField flex2 col-md-6 col-sm-12">
                  {/* <Controller
                    name="select"
                    className="border-0 "
                    control={control}
                    {...register("taskInputValue")}
                    render={({ field }) => (
                      <CreatableSelect
                        {...field}
                        styles={colourStyles}
                        className="findTasksFields border-0 border-mb-fields"
                        isClearable
                        options={optionTaskValue}
                        placeholder="Task"
                      />
                    )}
                  /> */}
                  <button className="taskbutton taskbuttonleft primary center">Find Student</button>
                  {/* {errors.taskInputValue && (
                    <span className="eror">This field is required</span>
                  )} */}
                </div>
              </form>
            </div>

            <div className="bannerLogos setResonive">
              <img src="/assets/images/home/Trusted by.svg" alt="" />
              <img src="/assets/images/home/SanMateo.png" alt="" />
              <img src="/assets/images/home/la.svg" alt="" />
              <img src="/assets/images/home/Sanjose.png" alt="" />
            </div>

            <div className="makeMobileOnly setResonive1 trust_mobileImage">
              <img src="/assets/images/home/Trusted by.svg" alt="" />
            </div>
            <div className="makeMobileOnly setResonive1 bannerLogos_mobile">
              {isiPhone ? (
                <>
                  <img src="/assets/images/home/SanMateo.png" alt="" />
                  <img src="/assets/images/home/la.svg" alt="" />
                  <img src="/assets/images/home/Sanjose.png" alt="" />
                </>
              ) : (
                <>
                  <img src="/assets/images/home/SanMateo.png" alt="" />
                  <img src="/assets/images/home/la.svg" alt="" />
                  <img src="/assets/images/home/Sanjose.png" alt="" />
                </>
              )}
            </div>
          </Col>
          <Col md={5} sm={12} className="mainbannerImagePart">
            <div className="banerkiImgDiv">
              <img className="mainHomeBannerImage" src="/assets/images/home/bannerImg.png" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeBanner;
