import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { createStudent } from "../../services/studentdetails/CreateStudent";
import { useNavigate } from "react-router-dom";
import { updateStudentDetail } from "../../services/studentdetails/UpdateStudentDetail";
// import Select, { ValueType } from "react-select";
import AsyncSelect from "react-select/async";
import { updateUser } from "../../services/users/UpdateUser";
import AuthContext from "../../context/AuthContext";
// import moment from "moment";

const CreateAccount = () => {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  // select gender function
  const [gender1, setGender1] = useState("0");
  const selectGender = (e) => {
    setGender1(e.target.value);
  };
  // following is for updating button values of gender
  useEffect(() => {}, [gender1]);

  const { locations } = useContext(AuthContext);
  // get location to show first
  const [randomLocation, setRandomLocation] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    const GetlocationsRamdomFunc = async () => {
      try {
        if (locations) {
          // Check if locations is a string (needs parsing) or already an array
          const parsedLocations = Array.isArray(locations) ? locations : JSON.parse(locations);
          setRandomLocation(parsedLocations.slice(0, 100)); // Set random 100
          setAllLocations(parsedLocations); // Store all locations
        }
      } catch (e) {
        console.error("Error loading locations:", e);
      }
    };

    GetlocationsRamdomFunc();
  }, [locations]);

  //valid date for date of birth
  const [monthValue, setMonthValue] = useState("");
  const limitizeMonth = (event, pattern) => {
    if (event.target.value.length == 0) {
      setMonthValue("");
    } else {
      const value_number = event.target.value.replace(/[^\d]/g, "");
      let valueTest = pattern.exec(value_number);
      // console.log('value: ', valueTest.index);
      if (valueTest.index == 0) {
        setMonthValue(value_number);
      }
    }
  };
  // date field limition
  const [dateValue, setDateValue] = useState("");
  const limitizeDate = (event, pattern) => {
    if (event.target.value.length == 0) {
      setDateValue("");
    } else {
      const value_number = event.target.value.replace(/[^\d]/g, "");
      let valueTest = pattern.exec(value_number);
      if (valueTest.index == 0) {
        setDateValue(value_number);
      }
    }
  };
  // year field limition
  const [yearValue, setYearValue] = useState("");
  const limitizeYear = (event, pattern) => {
    if (event.target.value.length == 0) {
      setYearValue("");
    } else {
      const value_number = event.target.value.replace(/[^\d]/g, "");
      let valueTest1 = pattern.test(value_number);
      if (valueTest1 || value_number.length < 4) {
        setYearValue(value_number);
      }
    }
  };

  const colourStyles = {
    singleValue: (styles) => ({
      ...styles,
      color: "#686868",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
    }),
  };
  // react hook form

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      select: {},
    },
  });

  const onSubmit = async (fData) => {
    // console.log(fData);
    setLoader(true);

    const std_age = Math.floor((new Date() - new Date(`${fData.year}-${fData.month}-${fData.day}`).getTime()) / 3.15576e10);
    if (std_age < 17) {
      Swal.fire({
        title: "Student Must be greater then 16.",
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
      setLoader(false);
      return false;
    }

    const data = {
      user_id: JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id,
      gender: parseInt(gender1),
      dob: `${fData.year}-${fData.month}-${fData.day}`,
      minimum_hourly_rate: 0,
      college: fData.collegeName,
    };

    let userApi_data;
    if (fData.location) {
      userApi_data = {
        location: parseInt(fData.location.value),
        // ssn: fData.socialSecNum,
      };
    } else {
      userApi_data = {
        // location: parseInt(fData.location.value),
        // ssn: fData.socialSecNum,
      };
    }
    // calling Api on condition
    // hit the below api if it is not first time
    if (localStorage.getItem("stepForms") && JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.student_details != null) {
      // const resp = await updateStudentDetail(data)
      // const resp_user = await updateUser(userApi_data)
      let [resp, resp_user] = await Promise.all([updateStudentDetail(data), updateUser(userApi_data)]);

      if (resp.status === 200 && resp_user.status === 200) {
        Swal.fire({
          title: resp.data.message,
          timer: 1500,
          icon: "success",
          showConfirmButton: false,
        });
        setTimeout(() => {
          navigate("/signUpRoute/step-two");
        }, 2200);
      } else {
        Swal.fire({
          title: resp.data.message,
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
        setLoader(false);
      }
    }
    // hit the below api if it is first time
    else {
      let [resp, resp_user] = await Promise.all([createStudent(data), updateUser(userApi_data)]);

      if (resp.status === 200 && resp_user.status === 200) {
        Swal.fire({
          title: resp.data.message,
          timer: 1500,
          icon: "success",
          showConfirmButton: false,
        });
        // adding value in local storage so that we can check when user update
        localStorage.setItem("stepForms", JSON.stringify("stepOne"));
        setTimeout(() => {
          navigate("/signUpRoute/step-two");
        }, 2200);
      } else {
        Swal.fire({
          title: resp.data.message,
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
        setLoader(false);
      }
    }
  };

  return (
    // profile info
    <>
      <div className="createAccount mbb-heading-font">
        <div style={{ zIndex: "9999" }} className="mb-green-arrow">
          <Link to="/">
            <img src="./assets/images/Vector_backIcon.svg" alt="" />
          </Link>
        </div>
        <div className="block-img-mb mb-head-img">
          <img src="/assets/images/Ellipse511.png" alt="123" />
        </div>
        {/* desktopStep */}
        <div className="stOneDiv setResonive">
          <img src="/assets/images/offer/Processbar/Group22664.svg" alt="" />
        </div>
        {/* mobileStep */}
        <div className="stOneDivResp setResonive1">
          <img src="/assets/images/offer/stepOneMobile.svg" alt="" />
        </div>

        <h2 className="mb-50">Profile Info</h2>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="createAccountForm mb-createAccountForm">
            <div className="inner">
              <div className="mb35">
                <p className="mb-1">Gender</p>
                <div className="gnder d-flex mb-non-mar">
                  <div className="selectGEndrDiv">
                    <input type="radio" value="0" onChange={selectGender} id="radioMale" checked={gender1 === "0"} />
                    <label htmlFor="radioMale" className="secondary gender-mb setColor">
                      Male
                    </label>
                  </div>
                  <div className="selectGEndrDiv">
                    <input type="radio" value="1" onChange={selectGender} id="radioFemale" checked={gender1 === "1"} />
                    <label htmlFor="radioFemale" className="secondary gender-mb setColor">
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb35">
                <p className="mb-1">Birth Date</p>
                <div className="bDate">
                  <input
                    placeholder="MM"
                    className="bDateInput bdate-mb"
                    type="text"
                    value={monthValue.length > 0 ? monthValue : ""}
                    {...register("month", {
                      required: true,
                      min: 1,
                      max: 12,
                      maxLength: 2,
                      onChange: (e) => limitizeMonth(e, /(0?[0?1-9]|1[012])$/),
                      // value: { monthValue },
                    })}
                  />
                  <input
                    placeholder="DD"
                    className="bDateInput bdate-mb"
                    type="text"
                    maxLength="2"
                    value={dateValue.length > 0 ? dateValue : ""}
                    {...register("day", {
                      required: true,
                      min: 1,
                      max: 31,
                      maxLength: 2,
                      onChange: (e) => limitizeDate(e, /(0?[0?1-9]|[12][0-9]|30|31)$/),
                      // value: { dateValue },
                    })}
                  />
                  <input
                    placeholder="YYYY"
                    className="bDateInput bdate-mb-1"
                    type="text"
                    maxLength="4"
                    value={yearValue.length > 0 ? yearValue : ""}
                    {...register("year", {
                      required: true,
                      min: 1900,
                      maxLength: 4,
                      onChange: (e) => limitizeYear(e, /(19|20)[0-9][0-9]$/),
                      // value: { yearValue },
                    })}
                  />
                </div>
                {errors.month && <span className="eror">Month must be between 1 and 12</span>}
                {errors.day && <span className="eror">Day must be between 1 and 31</span>}
                {errors.year && <span className="eror">Year must be above 1900 and in 4 digits</span>}
              </div>

              <div className="mb35">
                <p className="mb-1">School Name</p>
                <div>
                  <input
                    placeholder="E.g. Foothill College"
                    className="createFormLInput input-mb-create"
                    type="text"
                    {...register("collegeName", { required: true })}
                  />
                </div>
                {errors.collegeName && <span className="eror">This field is required</span>}
              </div>
              {/* <div className="mb35">
                <p className="mb-1">Social Security Number</p>
                <div className="ssnField_mobile">
                  <input
                    placeholder="000-00-0000"
                    className="createFormLInput input-mb-create"
                    type="text"
                    value={ssn_number}
                    {...register("socialSecNum", {
                      required: true,
                      onChange: (e) => handleInput(e),
                    })}
                  />
                </div>
                {errors.socialSecNum && (
                  <span className="eror">This field is required</span>
                )}
              </div> */}
              <div className="mb35">
                <p className="mb-1">Location</p>
                {/* , { required: true } */}
                <div>
                  {randomLocation.length > 0 ? (
                    <Controller
                      name="select"
                      control={control}
                      {...register("location", { required: true })}
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          className="StepOneSelect StepLocationSelect font15 mt-20"
                          styles={colourStyles}
                          cacheOptions
                          loadOptions={(inputValue, callback) => {
                            if (!inputValue) {
                              // Show a random subset of 100 locations if no input is provided
                              callback(randomLocation.slice(0, 100));
                            } else {
                              // Filter the entire list of locations based on input
                              const filteredOptions = allLocations.filter((location) => {
                                const [cityState, zip] = location?.label?.split(","); // Split into city/state and zip
                                return (
                                  cityState?.toLowerCase().startsWith(inputValue.toLowerCase()) || // Match city/state
                                  zip?.trim().startsWith(inputValue) // Match zip
                                );
                              });
                              callback(filteredOptions.slice(0, 100)); // Limit results to 100
                            }
                          }}
                          // defaultOptions={randomLocation}
                          placeholder="Enter your city or zip code"
                        />
                      )}
                    />
                  ) : (
                    <div>Loading locations...</div>
                  )}
                </div>
                {errors.location && <span className="eror">This field is required</span>}
              </div>
              <div className="gnder d-flex mb-d-flex">
                {loader ? (
                  <div className="relative">
                    <div className="loader alignLoader"></div>
                    <input type="button" disabled value="Continue" className="primary pl40 gender-mb-1" />
                  </div>
                ) : (
                  <input type="submit" value="Continue" className="primary gender-mb-1" />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
