import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { sendEmail } from "../../services/contactus/ContactUs";
import { createNotification } from "../../services/notifications/CreateNotification";
import { updateOrder } from "../../services/order/UpdateOrder";
import { decide } from "../../services/invitestudents/decision/decision";
import { getSingleTask } from "../../services/tasks/GetSingleTask";
import { emailUrl, handleImageError } from "../../utils/constants";
import Loader from "../loader/Loader";
import Modal from "react-bootstrap/Modal";
import { createChatRoom } from "../cutomerdashboard/customerinvitecards/CreatingChatRoom";

const StudentTaskDescp = () => {
  const [btnLoder, setBtnLoder] = useState(false);
  const [loder, setLoder] = useState(true);
  let navigate = useNavigate();
  let { taskId, orderId } = useParams();

  // student user
  let student_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  let student_Name =
    JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name.charAt(0).toUpperCase() +
    JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name.slice(1);
  // let student_LName = JSON.parse(localStorage.getItem('sparrowSignIn'))?.user?.last_name;
  let student_Email = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.email;
  // get order details data by sending task id
  // let;
  const [isSignedInStd, setIsSignedInStd] = useState();
  const [orderData, setOrderData] = useState([]);
  const [decision, setDecision] = useState(0);
  const [appliedFor, setAppliedFor] = useState(false);

  const getOrderFunc = async () => {
    const response = await getSingleTask(taskId);

    if (response.status === 200) {
      setOrderData(response.data.task);

      let filteredArray = null;
      let appliedArray = null;

      if (response?.data?.task?.task_details?.invited_students.length > 0) {
        filteredArray = response?.data?.task?.task_details?.invited_students.find((item) => item.student_id === student_id);
      }

      if (response?.data?.task?.task_details?.applied_students.length > 0) {
        appliedArray = response?.data?.task?.task_details?.applied_students.find((item) => item.student_id === student_id);
      }

      setDecision(filteredArray?.status);
      if (appliedArray == undefined) {
        setAppliedFor(false);
      } else {
        setAppliedFor(true);
      }

      const isSignedIn = response.data.task.task_details?.invitedStudents.find((item) => {
        return item.id === student_id;
      });
      setIsSignedInStd(isSignedIn);
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    getOrderFunc();
  }, [loder]);

  const applyForTask = async () => {
    setBtnLoder(true);

    const data = {
      task_id: orderData?.task_details?.id,
      student_id: student_id,
      message: message ?? null,
    };

    // const resp = await updateOrder(data, orderId);
    const resp = await decide(data);

    if (resp.status === 200) {
      const data = {
        // as reqirement by backend type is 0
        type: 0,
        to_id: orderData?.task_details?.client?.id,
        from_id: student_id,
        data: {
          title: "Task Accepted",
          message: `${student_Name} has applied for your task.`,
          action_url: `/dashboardRoute/customer-notification/task-details/${taskId}?orderId=${orderId}`,
        },
        read_at: null,
      };
      const resp = await createNotification(data);

      const dataForEmail = {
        to: orderData?.task_details?.client?.email,
        subject: `New student application for ${orderData?.task_details?.title}`,
        from: student_Email,
        task_name: orderData?.task_details?.title,
        first_name: orderData?.task_details?.client?.first_name,
        msg: orderData?.task_details?.description,
        action_url: `${emailUrl}/#/dashboardRoute/customer-notification/task-details/${taskId}/?orderId=${orderId}`,
        template: "student_applied_for_task",
        student_name: student_Name,
        message: message != "" || message != null ? message : null,
      };

      await sendEmail(dataForEmail);
      // if (resp.status === 200 && respForEmail.status === 200) {
      if (resp.status === 200) {
        await createChat(student_id, orderData?.task_details?.client?.id, orderData?.task_details?.order_draft?.id);
        setLoder(false);
        Swal.fire({
          title: "Wait for the client to contact you on the platform.",
          showConfirmButton: true,
          customClass: "stModal",
        });
        return;
      }
      setTimeout(() => {
        setLoder(false);
        // window.location.reload();
        navigate("/dashboardRoute/search-tasks/");
      }, 1000);
    }
    setMessage("");
  };

  const reArrangeArray = (array) => {
    let order = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };
    array.sort(function (a, b) {
      return order[a.day] - order[b.day];
    });
    return array;
  };
  // cleint token
  // const clientToken = orderData?.task_details?.client?.fcm_token;
  // updating order on button click
  const decideTask = async (id) => {
    // below for not adding loder on accept button on cliking of decline button
    if (id === 1) {
      setBtnLoder(true);
    }
    // 0 : pending
    // 1 : unpaid
    // 2 : progress
    // 3 : closed
    // 4 : canceled
    const data = {
      task_id: orderData?.task_details?.id,
      student_id: student_id,
      client_id: orderData?.task_details?.client?.id,
      status: "",
    };
    data.status = id;
    const resp = await decide(data);

    if (resp.status === 200) {
      // creating notifiaction for All notififcation tab as order is accepted
      if (id === 5) {
        // setBtnLoder(true)
        // create notification
        const data = {
          // as reqirement by backend type is 0
          type: 0,
          to_id: orderData?.task_details?.client?.id,
          from_id: student_id,
          data: {
            title: "Task Accepted",
            message: `Task accepted by ${student_Name}. Click here to connect.`,
            action_url: `/dashboardRoute/customer-notification/task-details/${taskId}?orderId=${orderId}`,
          },
          read_at: null,
        };
        const resp = await createNotification(data);

        // const notiForAdmin = {
        //     // as reqirement by backend type is 0
        //     type: 1,
        //     from_id: 1,
        //     data: {
        //         title: "New order added",
        //         message: `New Task ${orderData?.task_details?.title} created by ${orderData?.task_details?.client?.first_name} and accepted by ${student_Name}.`, action_url: ``
        //     },
        //     read_at: null
        // }
        // const resp_noti = await createNotification(notiForAdmin)

        // send notification
        // const data2 = {
        //     fcmToken: clientToken,
        //     title: `Order accepted by ${student_Name}`,
        //     body: `Order accepted by ${student_Name}`
        // }
        // const resp2 = await sendNotification(data2)
        // if (resp.status === 200 && resp2.status === 200) {
        // sending email
        const dataForEmail = {
          to: orderData?.task_details?.client?.email,
          subject: `Your ${orderData?.task_details?.title} task has been accepted`,
          from: student_Email,
          first_name: orderData?.task_details?.client?.first_name,
          msg: orderData?.task_details?.description,
          action_url: `${emailUrl}/#/dashboardRoute/customer-notification/task-details/${taskId}/?orderId=${orderId}`,
          template: "family_notification",
          student_name: student_Name,
        };

        await sendEmail(dataForEmail);
        // if (resp.status === 200 && respForEmail.status === 200) {
        if (resp.status === 200) {
          setLoder(true);
          Swal.fire({
            title: "Start a conversation with the client!",
            showConfirmButton: true,
            customClass: "stModal",
          });
        }
        setTimeout(async () => {
          setLoder(false);
          await createChat(student_id, orderData?.task_details?.client?.id, orderData?.task_details?.order_draft?.id);
        }, 1000);
      } else {
        // const dataForEmail = {
        //   to: orderData?.task_details?.client?.email,
        //   subject: `Your ${orderData?.task_details?.title} task has been canceled`,
        //   from: student_Email,
        //   first_name: orderData?.task_details?.client?.first_name,
        //   msg: orderData?.task_details?.description,
        //   action_url: `${emailUrl}/#/dashboardRoute/customer-notification/task-details/${taskId}/?orderId=${orderId}`,
        //   template: "student_notification_cancel_task",
        // };
        // const resp = await sendEmail(dataForEmail);
        // if (resp.status === 200) {
        //   Swal.fire({
        //     title: resp.data.message || "Email Sent",
        //     timer: 1500,
        //     icon: "success",
        //     showConfirmButton: false,
        //   });
        setTimeout(() => {
          navigate("/dashboardRoute/student-profile/");
        }, 1500);
      }
      // }
    }
  };

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const createChat = async (student_id, client_id, order_id) => {
    let std_String = student_id.toString();
    let client_String = client_id.toString();
    const chat_room_id = await createChatRoom(std_String, client_String, taskId, order_id, "student_personal_message", message);
    navigate(`/dashboardRoute/chatStudent?roomId=${chat_room_id}`);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    setErrorMessage("");
  };

  const handleEmptySendClick = () => {
    setMessage("");
    applyForTask();
    handleClose();
  };

  const handleSendClick = () => {
    if (message.trim() !== "") {
      applyForTask();
      handleClose();
    } else {
      setErrorMessage("Message cannot be empty!");
    }
  };

  return (
    <div>
      {loder ? (
        <div style={{ height: "100vh" }} className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="bgNotiLines mb-task-student">
          <Container>
            <>
              <Modal show={show} onHide={handleClose} centered size={"lg"}>
                <Modal.Body>
                  <p
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      margin: "0.5em 0",
                      color: "#333",
                      textAlign: "center",
                    }}
                    className="mb-3"
                  >
                    Send a Message?
                  </p>
                  <p
                    className="mb-4 text-center"
                    style={{
                      fontSize: "1rem",
                      color: "#C7C7CD",
                      textAlign: "center",
                      wordSpacing: 5,
                    }}
                  >
                    A personal introduction increases your chances of getting hired!
                  </p>
                  <textarea
                    placeholder="Write message......"
                    style={{ width: "100%", padding: "1%" }}
                    className="custom-textarea"
                    value={message}
                    onChange={handleInputChange}
                    maxLength={250}
                  />
                  {errorMessage && <p style={{ color: "red", marginTop: "5px" }}>{errorMessage}</p>}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="modalButtonSkip"
                    onClick={() => {
                      handleEmptySendClick();
                    }}
                  >
                    Skip
                  </button>
                  <button
                    className="primary modalButtonSend"
                    onClick={() => {
                      handleSendClick();
                    }}
                  >
                    Send
                  </button>
                </Modal.Footer>
              </Modal>
            </>
            <h2 className="mb57 mb-spac-task">
              Task <span className="green">Description</span>
            </h2>
            <Row>
              <Col md={4}>
                <div className="studentDetails setStduentDtails mb-setstudent-details">
                  <div className="studentDetailsImg mb-3 block">
                    <img src={orderData?.task_details?.client?.image} alt="" />
                  </div>
                  <h5 className="upH5 mb-3 block sprint1Fix">
                    {orderData?.task_details?.client?.first_name?.charAt(0).toUpperCase() + orderData?.task_details?.client?.first_name?.slice(1)}
                    {orderData?.task_details?.client?.last_name && " " + orderData?.task_details?.client?.last_name.charAt(0).toUpperCase()}.
                  </h5>
                  <div className="studentContent ml25 a-studentcontent-mb">
                    <div className="mb-2">
                      <span className="mobileWith25px">
                        <img src="/assets/images/customerDashboard/verified-dashboar.svg" alt="" />
                      </span>
                      &nbsp;&nbsp;
                      <span className="ml-7">{orderData?.task_details?.client?.email_verified_at ? "Verified" : "Not Verified"}</span>
                    </div>
                    <div className="mb-3 block">
                      <span className="mobileWith25px memberIcon">
                        <img src="/assets/images/customerDashboard/Members Icon.svg" alt="" />
                      </span>
                      &nbsp;&nbsp;
                      <span className="ml-7">
                        {orderData?.task_details?.invitedStudents.length > 0
                          ? `${orderData?.task_details?.invitedStudents.length} Invited students`
                          : "0 Invited"}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="studentDescription mb-student-desp wd75">
                  <div className="hourlyPayDiv mb-2 mb-hourlyPayDiv display-none-mb-mobile">
                    <div>
                      <img src="/assets/images/customerDashboard/hourlyvector.svg" alt="" />
                    </div>
                    <div>
                      <span>Hourly Pay</span>
                    </div>
                    <div>
                      <p>${orderData?.task_details?.hourly_rate}</p>
                    </div>
                  </div>
                  <div style={{ margin: "10px 5px" }} className="hourlyPayDiv mb-2 mb-hourlyPayDiv display-none-mb-desktop">
                    <div>
                      <img src="/assets/images/customerDashboard/hourlyvector.svg" alt="" />
                    </div>
                    <div>
                      <span className="heading-mb-stud">Hourly Pay</span>
                    </div>
                    <div>
                      <p>${orderData?.task_details?.hourly_rate}</p>
                    </div>
                  </div>
                  <div className="studentTaskDescpDiv mb-studnt-descp mobilePaddingFix">
                    {/* <div className="descp-flec-mb">
                      <div className="">
                        <h3 className="mb-description-head">Task Details</h3>
                      </div>
                      <div className="hourlyPayDiv mb-2 mb-hourlyPayDiv display-none-mb-desktop">
                        <div>
                          <img
                            src="/assets/images/customerDashboard/hourlyvector.svg"
                            alt=""
                          />
                        </div>
                        <div>
                          <span className="heading-mb-stud">Hourly Pay</span>
                        </div>
                        <div>
                          <p>${orderData?.task_details?.hourly_rate}</p>
                        </div>
                      </div>
                    </div> */}
                    <Row className="mb-row-padd stdtaskDespSection_mob paddingMobileSet">
                      <Col md={5} className="mb-respon-description">
                        {/* display-none-mb-mobile */}
                        <div className="studentTaskDescpParts ">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/jobtype.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Job Type</p>
                            <h5 style={{ fontFamily: "Poppins" }} className="teaskCtn p-mb-stud">
                              {orderData?.task_details?.type}
                            </h5>
                          </div>
                        </div>
                        <div className="studentTaskDescpParts">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/vectortask.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Task</p>
                            <p style={{ fontFamily: "Poppins" }} className="teaskCtn p-mb-stud">
                              {orderData?.task_details?.title}
                            </p>
                          </div>
                        </div>
                        <div className="studentTaskDescpParts">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/vectorlocation.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Location</p>
                            <p style={{ fontFamily: "Poppins" }} className="teaskCtn p-mb-stud">
                              {orderData?.task_details?.location?.city}, {orderData?.task_details?.location?.state}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={2} className="mb-task-paddding">
                        <div className="line"></div>
                      </Col>
                      <Col md={5} className="mb-respon-descrip mobileWidthFix">
                        <div className="studentTaskDescpParts">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/Vectordate.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Start Date</p>
                            <p className="teaskCtn p-mb-stud">{moment(orderData?.task_details?.start_date).format("MM-DD-YYYY")}</p>
                          </div>
                        </div>

                        <div className="studentTaskDescpParts">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/Vectordate.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">End Date</p>
                            <p className="teaskCtn p-mb-stud">{moment(orderData?.task_details?.end_date).format("MM-DD-YYYY")}</p>
                          </div>
                        </div>
                        {/* )} */}
                        <div className="studentTaskDescpParts ">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/vectortime.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Time</p>
                            <p>
                              {reArrangeArray(orderData?.availability)?.map((item) => {
                                return (
                                  <div>
                                    <div style={{ fontFamily: "Poppins" }} className="paragraph-mb-descrip">
                                      {item?.day.charAt(0).toUpperCase() + item?.day.slice(1)}:
                                    </div>
                                    <div style={{ fontFamily: "Poppins" }} className="paragraph-mb-descrip">
                                      {item.avail[0].start} - {item.avail[0].end}
                                    </div>
                                  </div>
                                );
                              })}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="studentTaskDescpDiv mb-studnt-descp ">
                    <p className="teaskHHed para-mb-stud">Task Description</p>
                    <p className="teaskCtn mt-2">{orderData?.task_details?.description}</p>
                  </div>
                  <div className="colorfulBtns display-none-mb-mobile">
                    {decision === 0 && orderData?.task_details?.status != "canceled" ? (
                      <>
                        <Link
                          onClick={() => {
                            decideTask(6);
                          }}
                          to=""
                          className="mainStyle bb2 p-mb-stud"
                        >
                          Decline
                        </Link>
                        {btnLoder ? (
                          <div className="relative inheritt">
                            <div className="loader alignLoader"></div>
                            <Link to="" className="mainStyle bb3 p-mb-stud">
                              Accept
                            </Link>
                          </div>
                        ) : (
                          <Link
                            onClick={() => {
                              decideTask(5);
                            }}
                            to=""
                            className="mainStyle bb3 p-mb-stud"
                          >
                            Accept
                          </Link>
                        )}
                      </>
                    ) : (decision === undefined || decision === null) && appliedFor === false ? (
                      <>
                        {btnLoder ? (
                          <div className="relative inheritt">
                            <div className="loader alignLoader"></div>
                            <Link to="" className="mainStyle bb3 p-mb-stud">
                              Connect
                            </Link>
                          </div>
                        ) : (
                          <Link
                            onClick={() => {
                              handleShow();
                            }}
                            to=""
                            className="mainStyle bb3 p-mb-stud"
                          >
                            Connect
                          </Link>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="colorfulBtns display-none-mb-desktop">
                    {decision === 0 && orderData?.task_details?.status != "canceled" ? (
                      <>
                        <Link
                          onClick={() => {
                            decideTask(6);
                          }}
                          to=""
                          className="mainStyle bb2 p-mb-stud"
                        >
                          Decline
                        </Link>
                        {btnLoder ? (
                          <div className="relative inheritt">
                            <div className="loader alignLoader"></div>
                            <Link to="" className="mainStyle bb3 p-mb-stud">
                              Accept
                            </Link>
                          </div>
                        ) : (
                          <Link
                            onClick={() => {
                              decideTask(5);
                            }}
                            to=""
                            className="mainStyle bb3 p-mb-stud"
                          >
                            Accept
                          </Link>
                        )}
                      </>
                    ) : decision === undefined && appliedFor === false ? (
                      <>
                        {btnLoder ? (
                          <div className="relative inheritt">
                            <div className="loader alignLoader"></div>
                            <Link to="" className="mainStyle bb3 p-mb-stud">
                              Connect
                            </Link>
                          </div>
                        ) : (
                          <Link
                            onClick={() => {
                              handleShow();
                            }}
                            to=""
                            className="mainStyle bb3 p-mb-stud"
                          >
                            Connect
                          </Link>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default StudentTaskDescp;
