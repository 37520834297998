import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { timeSlots, days } from "../../services/availibility&timeslots/Availibility";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { getLocations100Random } from "../../services/locations/Locations";
import { SearchStudent } from "../../services/users/SearchStudent";
import Swal from "sweetalert2";
import AuthContext from "../../context/AuthContext";

// import CreatableSelect from "react-select/creatable";
import { isMobile } from "react-device-detect";
import { colourStyles, colourStylesLocation } from "../../utils/constants";
import { searchTasks } from "../../services/tasks/SearchTasks";
import { taskSearchSuggestions } from "../../services/tasks/TaskSearchSuggestions";
import ReactSelect from "react-select";

const AdvancedSearchBarTask = (props) => {
  const [loder, setLoder] = useState(true);

  const auth = useContext(AuthContext);
  const { locations } = useContext(AuthContext);
  const [adv_toBeShow, setAdv_toBeShow] = useState(isMobile ? false : true);

  const handleToBeShow = () => {
    if (adv_toBeShow) {
      setAdv_toBeShow(false);
    } else setAdv_toBeShow(true);
  };
  const imageForDropDownAdv = adv_toBeShow ? "/assets/images/viewAll/ArrowUp.svg" : "/assets/images/viewAll/ArrowDown.svg";

  // get timing slots
  const [timeSlot, setTimeSlot] = useState();
  const getTimeFunc = async () => {
    const response1 = await timeSlots();

    if (response1.status === 200) {
      setTimeSlot(response1.data);
      setLoder(false);
    } else {
      Swal.fire({
        title: response1.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  // get working days
  const [workdays, setworkdays] = useState();
  const getDaysFunc = async () => {
    const response = await days();

    if (response.status === 200) {
      setworkdays(response.data);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  // get location to show first
  const [randomLocation, setRandomLocation] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    const GetlocationsRamdomFunc = async () => {
      try {
        if (locations) {
          // Check if locations is a string (needs parsing) or already an array
          const parsedLocations = Array.isArray(locations) ? locations : JSON.parse(locations);
          setRandomLocation(parsedLocations.slice(0, 100)); // Set random 100
          setAllLocations(parsedLocations); // Store all locations
        }
      } catch (e) {
        console.error("Error loading locations:", e);
      }
    };

    GetlocationsRamdomFunc();
  }, [locations]);

  useEffect(() => {
    getDaysFunc();
    getTimeFunc();
  }, []);

  const optionDay = [];
  if (workdays?.length > 0) {
    let order = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };

    workdays.sort(function (a, b) {
      return order[a.day] - order[b.day];
    });

    workdays?.map((singleDay) => {
      return optionDay.push({
        label: singleDay?.day.charAt(0).toUpperCase() + singleDay?.day.slice(1),
        value: singleDay.id,
      });
    });
  }

  const optionTime = [];
  if (timeSlot?.length > 0) {
    timeSlot?.map((singleTime) => {
      return optionTime.push({
        label: singleTime?.slot,
        value: singleTime.id,
      });
    });
  }

  // below code exist for checking and unchecking checkbox also making mandatory to select only one checkbox at a time
  // for rating
  // for hourly wage
  const [selectedOption1, setSelectedOption1] = useState(null);

  const handleOptionChange1 = (e) => {
    setSelectedOption1(e.target.value === selectedOption1 ? null : e.target.value);
  };
  // for transportation
  const [jobType, setJobType] = useState(null);

  const handleOptionChange2 = (e) => {
    setJobType(e.target.value === jobType ? null : e.target.value);
  };

  // task field
  // option for task to make it option
  const [optionTaskValue, setOptionTaskValue] = useState([]);
  const [selectedValue1, setSelectedValue1] = useState("");

  // console.log(selectedValue1);
  // API hitting
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (fData) => {
    let dataObj = {}; // Initialize as an empty object

    if (fData.daySearch) {
      dataObj["day"] = parseInt(fData.daySearch?.value);
    }
    if (fData.timeSearchStart) {
      dataObj["start_time"] = parseInt(fData.timeSearchStart?.value);
    }

    // if (fData.timeSearchEnd) {
    //   dataObj["end_time"] = parseInt(fData.timeSearchEnd?.value);
    // }

    // if (
    //   parseInt(fData.timeSearchStart?.value) >
    //   parseInt(fData.timeSearchEnd?.value)
    // ) {
    //   Swal.fire({
    //     title: "Starting Time can not be greater than the Ending Time",
    //     timer: 2500,
    //     icon: "warning",
    //     showConfirmButton: false,
    //   });
    //   return;
    // }

    // transportation
    if (jobType && jobType == "one-time") {
      dataObj["jobType"] = 0;
    } else if (jobType && jobType == "recurring") {
      dataObj["jobType"] = 1;
    }

    // hourly wage
    if (selectedOption1) {
      if (selectedOption1.includes("-")) {
        let wageArray = selectedOption1.split("-");
        let min = wageArray[0];
        let max = wageArray[1];
        dataObj["minimum_hourly_rate"] = parseInt(min);
        dataObj["maximum_hourly_rate"] = parseInt(max);
      } else {
        dataObj["minimum_hourly_rate"] = parseInt(selectedOption1);
      }
    }

    //for location
    if (fData.location && fData.location.label) {
      let arrayForCityZip = fData.location.label.split(",");
      dataObj["zip"] = parseInt(arrayForCityZip[1]);
    }

    if (selectedValue1 != null || selectedValue1 != "" || selectedValue1 != undefined) {
      dataObj["task_category"] = selectedValue1.value;
    }

    const resp = await searchTasks(dataObj);

    if (resp.status === 200) {
      console.log(resp?.data?.data);
      if (resp?.data?.data == 0) {
        Swal.fire({
          title: "No tasks found.",
          timer: 1500,
          icon: "warning",
          showConfirmButton: false,
        });
        props.updateStudentResult([]);
      } else {
        Swal.fire({
          title: "Tasks Found.",
          timer: 1500,
          icon: "success",
          showConfirmButton: false,
        });
        setTimeout(() => {
          props.updateStudentResult(resp?.data?.data);
          props.searchedValue(selectedValue1?.value);
          // props.searchedValue(fData.taskInputValue.value);
          // navigate('/signUpRoute/step-two');
        }, 100);
      }
    } else {
      Swal.fire({
        title: resp.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const taskSuggestions = async (searched_value) => {
    try {
      const data = {
        searched_value: searched_value,
      };
      const resp = await taskSearchSuggestions(data);

      if (resp.status === 200) {
        // Ensure resp.data is an array before setting optionTaskValue
        const suggestions = Array.isArray(resp?.data?.data) ? resp?.data?.data : [];
        setOptionTaskValue(suggestions);
      }
    } catch (error) {
      console.error("Error fetching task suggestions:", error);
    }
  };

  const taskCategoriesOptions = [
    { label: "Child Care", value: "Child Care", isParent: true },
    { label: "Tutoring", value: "Tutoring", isParent: true },
    // { label: "Business", value: "Business", isParent: false },
    // { label: "Chemistry", value: "Chemistry", isParent: false },
    // { label: "Chinese", value: "Chinese", isParent: false },
    // { label: "English", value: "English", isParent: false },
    // { label: "French", value: "French", isParent: false },
    // { label: "Geography", value: "Geography", isParent: false },
    // { label: "German", value: "German", isParent: false },
    // { label: "History", value: "History", isParent: false },
    // { label: "Math", value: "Math", isParent: false },
    // { label: "Science", value: "Science", isParent: false },
    // { label: "Social Studies", value: "Social Studies", isParent: false },
    // {
    //   label: "Religious Education",
    //   value: "Religious Education",
    //   isParent: false,
    // },
    { label: "Pet Sitting", value: "Pet Sitting", isParent: true },
    { label: "Yard Work", value: "Yard Work", isParent: true },
    { label: "Grocery Delivery", value: "Grocery Delivery", isParent: true },
    { label: "Housekeeping", value: "Housekeeping", isParent: true },
    { label: "Elder Care", value: "Elder Care", isParent: true },
    { label: "Window Cleaning", value: "Window Cleaning", isParent: true },
    { label: "Moving", value: "Moving", isParent: true },
    { label: "Sports Coaching", value: "Sports Coaching", isParent: true },
    // { label: "Baseball", value: "Baseball", isParent: false },
    // { label: "Basketball", value: "Basketball", isParent: false },
    // { label: "Boxing Football", value: "Boxing Football", isParent: false },
    // { label: "Golf", value: "Golf", isParent: false },
    // { label: "Soccer", value: "Soccer", isParent: false },
    // { label: "Softball", value: "Softball", isParent: false },
    // { label: "Swimming", value: "Swimming", isParent: false },
    // { label: "Tennis", value: "Tennis", isParent: false },
    // { label: "Track & Field", value: "Track & Field", isParent: false },
    // { label: "Volleyball", value: "Volleyball", isParent: false },
    // { label: "Water Polo", value: "Water Polo", isParent: false },
    // { label: "Wrestling", value: "Wrestling", isParent: false },
    { label: "Other", value: "Other", isParent: true },
  ];

  // const colourStyles_task = {
  //   menu: (styles) => ({ ...styles, backgroundColor: "white", zIndex: 999 }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
  //     ...styles,
  //     paddingLeft: data.isParent ? "5%" : "15%", // Add padding for child options
  //     color: data.isParent ? "#898A8D" : "#82D051", // Change color based on whether it's a parent or child
  //     cursor: "default",
  //     backgroundColor: isDisabled
  //       ? "#cacaca"
  //       : isSelected
  //       ? "#82D051"
  //       : isFocused
  //       ? undefined
  //       : undefined,
  //     color: isDisabled
  //       ? "#cacaca"
  //       : isSelected
  //       ? "#fcfcfc"
  //       : isFocused
  //       ? "#898A8D"
  //       : "#898A8D",
  //     cursor: isDisabled ? "not-allowed" : "default",
  //     ":active": {
  //       ...styles[":active"],
  //       backgroundColor: "#82D051",
  //     },
  //     fontSize: window.innerWidth < 512 ? "14px" : "inherit",
  //   }),
  //   singleValue: (styles) => ({
  //     ...styles,
  //     color: "#898a8de6",
  //     fontFamily: "Poppins",
  //     fontWeight: 400,
  //   }),
  // };

  return (
    <div className="advanceSearch mt-5">
      <form id="advanceSearchForm" onSubmit={handleSubmit(onSubmit)} className="stepForm">
        <div className="bannerFields viewStudentsField pb-4 reviewSection_dashbord row">
          <div style={{ textAlign: "left" }} className="col-lg-5 col-md-6 col-sm-6 col-12 px-0">
            {randomLocation.length > 0 ? (
              <Controller
                name="select"
                className="border-0"
                control={control}
                {...register("location")}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    styles={colourStylesLocation}
                    className="cityField border-0 border-mb-fields"
                    cacheOptions
                    loadOptions={(inputValue, callback) => {
                      if (!inputValue) {
                        // Show a random subset of 100 locations if no input is provided
                        callback(randomLocation.slice(0, 100));
                      } else {
                        // Filter the entire list of locations based on input
                        const filteredOptions = allLocations.filter((location) => {
                          const [cityState, zip] = location?.label?.split(","); // Split into city/state and zip
                          return (
                            cityState?.toLowerCase().startsWith(inputValue.toLowerCase()) || // Match city/state
                            zip?.trim().startsWith(inputValue) // Match zip
                          );
                        });
                        callback(filteredOptions.slice(0, 100)); // Limit results to 100
                      }
                    }}
                    // defaultOptions={randomLocation.slice(0, 100)}
                    placeholder="City or zip code"
                    menuPortalTarget={document.body}
                  />
                )}
              />
            ) : (
              <div>Loading locations...</div>
            )}
          </div>
          <div className="customSearchField mb-customSearchField  col-lg-7 col-md-6 col-sm-6 col-12">
            <Controller
              name="select_task"
              {...register("taskInputValue")}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={selectedValue1}
                  // onMenuOpen={handleMenuOpen}
                  onChange={(newValue) => {
                    setSelectedValue1(newValue);
                  }}
                  onCreateOption={null}
                  styles={colourStylesLocation}
                  options={taskCategoriesOptions}
                  menuPortalTarget={document.body}
                  className="findTasksFields border-0 border-mb-fields"
                  placeholder="Task"
                />
              )}
              control={control}
            />

            {errors.taskInputValue && <span className="eror">This field is required</span>}
            <button type="submit" className="taskbutton primary center">
              Find Task
            </button>
          </div>
        </div>
        <div className="advanceSearchHed">
          <div className="pointercurser" onClick={handleToBeShow}>
            <h6 className="cjorta">
              {" "}
              <span>
                <img src="/assets/images/viewAll/advSearchIcon.svg" alt="" />
                &nbsp;&nbsp;&nbsp;
              </span>
              Advanced Search
            </h6>
          </div>
          <div>
            <img className="cjotakro" src={imageForDropDownAdv} alt="" onClick={handleToBeShow} />
          </div>
        </div>
        {adv_toBeShow && (
          <div
            className="advanceSearchBody row"
            // style={{ placeContent: "space-evenly" }}
          >
            {/*  */}
            <div className="advanceBodyParts advanceBodyPartsRes  col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Time</h5>
                <div className="selectDivAdv">
                  <Controller
                    name="daySearch"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} placeholder="Select day" className="advSelectViewAll mb-2" options={optionDay} styles={colourStyles} />
                    )}
                  />

                  <Controller
                    name="timeSearchStart"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} placeholder="Start time" className="advSelectViewAll mb-2" options={optionTime} styles={colourStyles} />
                    )}
                  />
                  {/* <Controller
                    name="timeSearchEnd"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="End time"
                        className="advSelectViewAll mb-2"
                        options={optionTime}
                        styles={colourStyles}
                      />
                    )}
                  /> */}
                </div>
              </div>
            </div>

            <div className="advanceBodyParts advanceBodyPartsRes  col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Job Type</h5>
                <div className="advanceBodyDistance">
                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="jobType"
                        id="jobType"
                        value="one-time"
                        type="checkbox"
                        checked={jobType === "one-time"}
                        onChange={handleOptionChange2}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>One-Time Job</label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="jobType"
                        id="jobType"
                        value="recurring"
                        type="checkbox"
                        checked={jobType === "recurring"}
                        onChange={handleOptionChange2}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>Recurring Job</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="advanceBodyParts advanceBodyPartsRes  col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="innerSetting">
                <h5 className="mb-2">Hourly Pay</h5>
                <div className="advanceBodyDistance">
                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="minWage"
                        id="minWage"
                        value="20-30"
                        // type="radio"
                        // {...register("minWage")}
                        type="checkbox"
                        checked={selectedOption1 === "20-30"}
                        onChange={handleOptionChange1}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>$20 - $30</label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="minWage"
                        id="minWage"
                        value="30-40"
                        // type="radio"
                        // {...register("minWage")}
                        type="checkbox"
                        checked={selectedOption1 === "30-40"}
                        onChange={handleOptionChange1}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>$30 - $40</label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-row mt-1">
                    <div className="pretty p-default p-round advanceBtn">
                      <input
                        name="minWage"
                        id="minWage"
                        value="40"
                        // type="radio"
                        // {...register("minWage")}
                        type="checkbox"
                        checked={selectedOption1 === "40"}
                        onChange={handleOptionChange1}
                      />
                      <div className="state p-success-o">
                        <label></label>
                      </div>
                    </div>
                    <div className="ms-1">
                      <div className="state p-success-o">
                        <label>$40 and above</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AdvancedSearchBarTask;
