import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { storeToken } from "../services/notifications/StoreToken";
import { firebaseConfig } from "./FireBase";

// const isSocialMediaBrowser = /iPhone/.test(navigator.userAgent) && /(Instagram|Facebook|Twitter)/i.test(navigator.userAgent);

let messaging;
if (
  !navigator.userAgent.match(/(FBIOS|FBAN|FBAV)/) && // Matches Facebook iOS app
  !navigator.userAgent.match(/Instagram/) && // Matches Instagram app
  !navigator.userAgent.match(/(FB_IAB|Instagram)/) && // Matches Facebook and Instagram in-app browsers
  !navigator.userAgent.match(/(FBForIPhone|FBDV)/) &&
  !navigator.userAgent.match(/iPhone/) // Matches additional Facebook patterns
) {
  messaging = getMessaging();
}

export const requestForToken = async () => {
  // debugger;

  if (
    navigator.userAgent.match(/(FBIOS|FBAN|FBAV)/) && // Matches Facebook iOS app
    navigator.userAgent.match(/Instagram/) && // Matches Instagram app
    navigator.userAgent.match(/(FB_IAB|Instagram)/) && // Matches Facebook and Instagram in-app browsers
    navigator.userAgent.match(/(FBForIPhone|FBDV)/) &&
    navigator.userAgent.match(/iPhone/) // Matches additional Facebook patterns
  ) {
    return;
  }
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  try {
    const currentToken = await getToken(messaging, {
      vapidKey: "BJ6f64FGOAJDTJacLolob6gnII5m61DvzhpvGnPkF4WZRebvLdC9YmQQKbo_qRrKa72__FOSsAD9kJy0Y1sM9QE",
    });
    if (currentToken) {
      // sending token in backend db
      let data = {
        token: currentToken,
      };
      // storing token for backend
      const respForToken = await storeToken(data);
      if (respForToken.status === 200) {
      }
    }
  } catch (err) {
    console.log("firebase " + err);
  }
};
