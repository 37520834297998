import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { createNotification } from "../../../services/notifications/CreateNotification";
import ReactStars from "react-stars";
import { inviteStudent } from "../../../services/invitestudents/InviteStudent";
import Swal from "sweetalert2";
import { saveStudent } from "../../../services/savestudents/SaveStudent";
import { ShowAllStudents } from "../../../services/users/ShowAllStudents";
import { deleteStudent } from "../../../services/savestudents/DeleteStudent";
import { sendEmail } from "../../../services/contactus/ContactUs";
import { emailUrl } from "../../../utils/constants";
import { getSingleOrderDraft } from "../../../services/order/GetSingleOrderDraft";
import { getSingleTask } from "../../../services/tasks/GetSingleTask";

export default function StudentInviteCard({ studentData, setAllStudent, singleOrder, searchedValue }) {
  let { taskId, orderId } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  const [btnLoder, setBtnLoder] = useState(false);
  const [isInvited, setIsInvited] = useState(studentData?.isInvited); // Track invite status
  const [isSaved, setIsSaved] = useState(() => {
    // Determine if the student is saved initially
    return !!studentData?.student_saved_by?.length || !!studentData?.student_saved_id;
  });

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  // defining userrole and user id
  // let user_role = JSON.parse(localStorage.getItem('sparrowSignIn'))?.user?.role;
  let client_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  let client_Name = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name;
  // let client_LName = JSON.parse(localStorage.getItem('sparrowSignIn'))?.user?.last_name;
  let client_Email = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.email;

  let countPrimaryTask = 0;
  let terminateLoop = false;

  let pathPage;
  if (localStorage.getItem("sparrowSignIn")) {
    pathPage = `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`;
  }

  let studentMinumunHourlyRate = 0;
  if (studentData?.student_details?.minimum_hourly_rate) {
    studentMinumunHourlyRate = studentData?.student_details?.minimum_hourly_rate;
  } else if (studentData?.minimum_hourly_rate) {
    studentMinumunHourlyRate = studentData?.minimum_hourly_rate;
  }
  // papulate dateofBirth variable from home page featured api aur student api
  let studentDateOfBirth = studentData?.student_details?.dob
    ? studentData?.student_details?.dob && " " + getAge(studentData?.student_details?.dob) + "'"
    : studentData?.dob && " " + getAge(studentData?.dob) + "'";

  let skillValue = studentData?.student_details?.skills ? studentData?.student_details?.skills : studentData?.skills;

  function extractSkillsFromUrl(url) {
    // Extract the query string part after the `?`
    const queryString = url.split("?")[1];

    if (queryString) {
      // Parse the query string
      const params = new URLSearchParams(queryString);

      // Get the `skills` parameter
      const skills = params.get("skills");

      return skills; // Returns the value of `skills`, or null if not present
    }

    return null; // Return null if there is no query string
  }

  const skills = extractSkillsFromUrl(window.location.href);

  let resultForPrimaryTask;
  if (skills == null) {
    resultForPrimaryTask =
      skillValue?.length > 0
        ? skillValue
            .split(/\s*,\s*/)
            .sort((a, b) => {
              // Define a custom sorting function based on the presence of searchedValue
              const containsA = a.toLowerCase().includes(searchedValue?.toLowerCase());
              const containsB = b.toLowerCase().includes(searchedValue?.toLowerCase());

              if (containsA && !containsB) {
                return -1; // a should come before b
              } else if (containsB && !containsA) {
                return 1; // b should come before a
              } else {
                return a.length - b.length; // Sort by length if neither contains searchedValue
              }
            })
            .map((item, index) => {
              if (!terminateLoop) {
                let displayItem = item;

                // Replace "|" with " | "
                if (displayItem.includes("|")) {
                  displayItem = displayItem.replace(/\|/g, " | ");
                }

                // Adjust displayItem if it's too long
                if (displayItem.length > 28) {
                  if (displayItem.toLowerCase().includes("sports coaching")) {
                    displayItem = "Coaching";
                  } else if (displayItem.toLowerCase().includes("tutoring")) {
                    displayItem = "Tutoring";
                  }
                } else {
                  if (displayItem.toLowerCase().includes("sports coaching")) {
                    displayItem = displayItem.replace("Sports Coaching:", "Coaching: ");
                  }
                }

                // Now, calculate the length
                const itemLength = displayItem.length;

                // Check if adding this item will exceed 28 characters
                if (countPrimaryTask + itemLength <= 28 && index < 4) {
                  countPrimaryTask += itemLength;
                  return <span key={index}>{displayItem.trim()}</span>;
                } else {
                  terminateLoop = true;
                  return (
                    <Link
                      to={{
                        pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`,
                      }}
                      role="button"
                      key={index}
                    >
                      <img src="/assets/images/customerDashboard/ActionsPlus_bold.svg" className="imageForMoreTask" alt="" />
                    </Link>
                  );
                }
              }
              return null;
            })
        : "";
  } else {
    resultForPrimaryTask =
      skillValue?.length > 0 && skills
        ? skillValue
            .split(/\s*,\s*/) // Split the skills into individual parts
            .filter((skill) => {
              if (skills) {
                // If skills exist in parsedstoredDataJSON, match against it
                const storedSkill = skills.toLowerCase();
                return skill?.toLowerCase()?.includes(storedSkill);
              } else {
                // If skills are null, just ensure skill is a valid string
                return skill?.toLowerCase();
              }
            })
            .sort((a, b) => {
              // Define a custom sorting function based on the presence of searchedValue
              const containsA = a?.toLowerCase()?.includes(searchedValue?.toLowerCase());
              const containsB = b?.toLowerCase()?.includes(searchedValue?.toLowerCase());

              if (containsA && !containsB) {
                return -1; // a should come before b
              } else if (containsB && !containsA) {
                return 1; // b should come before a
              } else {
                return a.length - b.length;
              }
            })
            .map((item, index) => {
              if (!terminateLoop) {
                let displayItem = item;

                if (displayItem.toLowerCase().includes("tutoring:")) {
                  displayItem = displayItem.replace("Tutoring:", "");
                }

                if (displayItem.toLowerCase().includes("sports coaching")) {
                  displayItem = displayItem.replace("Sports Coaching:", "");
                }

                if (displayItem.includes("|")) {
                  displayItem = displayItem.replace(/\|/g, " | ");
                }

                // Count character length and limit display
                if (countPrimaryTask <= 28 && index < 4) {
                  return <span key={index}>{displayItem.trim()}</span>;
                } else {
                  terminateLoop = true;
                  return (
                    <Link
                      to={{
                        pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`,
                        search: location.search, // Preserve current searchParams
                      }}
                      role="button"
                      key={index}
                    >
                      <img src="/assets/images/customerDashboard/ActionsPlus_bold.svg" className="imageForMoreTask" alt="" />
                    </Link>
                  );
                }
              }
              return null;
            })
        : "";
  }
  // inviting the student
  // useref for checking if the function is called first time so only at that time create order APi is hitted

  const [classOfInvite, setClassOfInvite] = useState("primary bookNowBtn");

  useEffect(() => {
    if (studentData?.invited_students?.length > 0) {
      setIsInvited(true);
    }
    return () => {
      sessionStorage.removeItem("sparrowFunc");
    };
  }, []);

  const InviteStudent = async (std_id, std_email, event) => {
    event.preventDefault();
    setBtnLoder(true);
    // creating invite students number for record
    const data2 = {
      task_id: taskId,
      client_id: client_id,
      student_id: std_id,
    };
    const response = await inviteStudent(data2);
    const task = await getSingleTask(taskId);
    if (response.status === 200) {
      // debugger;
      // this case is for baad wala flow
      const currentURL = window.location.href;

      const urlParts = currentURL.split("/");
      const task_id = urlParts[urlParts.length - 2];
      const order_draft_id = urlParts[urlParts.length - 1];

      if (orderId === "null" && !sessionStorage.getItem("sparrowFunc")) {
        const gettingSingleOrderResp = await getSingleOrderDraft(task_id);

        // setGeneratedOrderId(respOfOrder?.data?.order?.id)
        sessionStorage.setItem("inviteGeneratedOrderId", order_draft_id);
        // creating notifiaction for All notififcation tab
        const data1 = {
          // as reqirement by backend type is 0
          type: 0,
          to_id: std_id,
          from_id: client_id,
          data: {
            title: "Task Recieved",
            message: `Task recieved from ${client_Name?.charAt(0).toUpperCase() + client_Name?.slice(1)}`,
            action_url: `/dashboardRoute/task-history/student-task-descp/${taskId}/${order_draft_id}`,
          },
          read_at: null,
        };
        await createNotification(data1);

        // sending email
        const dataForEmail = {
          to: std_email,
          subject: gettingSingleOrderResp?.data?.order?.order_details?.task?.title,
          from: client_Email,
          first_name: studentData?.first_name,
          client_name: client_Name?.charAt(0).toUpperCase() + client_Name?.slice(1),
          // last_name: studentData?.last_name,
          msg: gettingSingleOrderResp?.data?.order?.order_details?.task?.description,
          action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${taskId}/${order_draft_id}`,
          template: "student_invited",
          task_details: `${task?.data?.task?.task_details?.title} - $${task?.data?.task?.task_details?.hourly_rate}/hr`,
        };
        const respForEmail = await sendEmail(dataForEmail);
        if (respForEmail.status === 200) {
          sessionStorage.setItem("sparrowFunc", "1");
          setClassOfInvite("primary bookNowBtn disalbed");
          // console.log("Noti Created")
          setBtnLoder(false);
          return;
        }
      } else {
        const gettingSingleOrderResp = await getSingleOrderDraft(task_id);
        let data1;
        let dataForEmail;

        data1 = {
          // as reqirement by backend type is 0
          type: 0,
          to_id: std_id,
          from_id: client_id,
          data: {
            title: "Task Recieved",
            message: `Task recieved from ${client_Name?.charAt(0).toUpperCase() + client_Name?.slice(1)}. `,
            action_url: `/dashboardRoute/task-history/student-task-descp/${taskId}/${order_draft_id}`,
          },
          read_at: null,
        };
        // sending email
        dataForEmail = {
          to: std_email,
          subject: gettingSingleOrderResp?.data?.order?.order_details?.task?.title,
          from: client_Email,
          first_name: studentData?.first_name,
          client_name: client_Name?.charAt(0).toUpperCase() + client_Name?.slice(1),
          // last_name: studentData?.last_name,
          msg: gettingSingleOrderResp?.data?.order?.order_details?.task?.description,
          action_url: `${emailUrl}/#/dashboardRoute/task-history/student-task-descp/${taskId}/${order_draft_id}`,
          template: "student_invited",
          task_details: `${task?.data?.task?.task_details?.title} - $${task?.data?.task?.task_details?.hourly_rate}/hr`,
        };

        const resp1 = await createNotification(data1);
        await sendEmail(dataForEmail);
        // if (resp.status === 200 && resp1.status === 200 && response.status === 200) {
        if (resp1.status === 200 && response.status === 200) {
          setClassOfInvite("primary bookNowBtn disalbed");
          setIsInvited(true); // Mark as invited
          // console.log("Noti Created")
          setBtnLoder(false);
        }
      }
    } else {
      Swal.fire({
        title: "You have reached the invitations limit for today",
        timer: 2500,
        icon: "error",
        showConfirmButton: false,
      });
      setBtnLoder(false);
    }
  };

  // saving student func
  // defing userrole and user id
  let user_role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;
  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  // saving students
  const action_AddToFav = async (std_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      if (user_role === "client") {
        const data = { client_id: user_id, student_id: std_id };
        const resp = await saveStudent(data);
        setIsSaved(true); // Mark as saved
        await ShowAllStudents(2, JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id);

        if (resp.status === 200) {
          setIsSaved(true); // Mark as saved
        } else {
          Swal.fire({
            title: resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
          setIsSaved(false); // Mark as saved
        }
      } else {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };

  // removing savedstudents Func

  const action_DeleteFav = async (std_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      if (user_role === "client") {
        const resp = await deleteStudent(std_id);
        setIsSaved(false); // Mark as saved
        await ShowAllStudents(2, JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id);

        if (resp.status === 200) {
          setIsSaved(false); // Mark as saved
        } else {
          Swal.fire({
            title: resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
          setIsSaved(true); // Mark as saved
        }
      } else {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };

  return (
    <div className="profileBox border-mb-fields w-100 inviteStdCard_class">
      <div className="profileContainer">
        <div className="profileImg">
          <img className="profileImgM imgBorder" src={studentData?.image} alt="" />
          {studentData?.totalOrders > 50 ? (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/goldA.svg" alt="" />
              <span className="hoverText setResonive">This student has done over 100 tasks</span>
            </div>
          ) : studentData?.totalOrders > 25 && studentData?.totalOrders <= 50 ? (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/silverb.svg" alt="" />
              <span className="hoverText setResonive">This student has done 25-50 tasks</span>
            </div>
          ) : (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/bronzec.svg" alt="" />
              <span className="hoverText setResonive">This student has done 0-25 tasks</span>
            </div>
          )}
          <div className={`hoverTextDiv`}>
            {isSaved || studentData?.student_saved_by?.length > 0 || studentData?.student_saved_id ? (
              // Render "Remove from saved students"
              <div style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => action_DeleteFav(studentData.id)}>
                <img src="/assets/images/home/goldenlabelicon.png" alt="Remove from saved students" className="romaalimg favClassLabel" />
                <span className="hoverText setResonive">Remove from saved students</span>
              </div>
            ) : (
              // Render "Add to favorite students"
              <div style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => action_AddToFav(studentData?.id)}>
                <img src="/assets/images/home/greynlabelicon.png" alt="Add to favorite students" className="romaalimg" />
                <span className="hoverText setResonive">Add to favorite students</span>
              </div>
            )}
          </div>
        </div>
        <div
          className="profilHed"
          onClick={() =>
            navigate(pathPage, {
              state: {
                query: {
                  taskId: taskId,
                  orderId: orderId,
                  stdFirstName: studentData?.first_name,
                  stdLastName: studentData?.last_name,
                  stdEmail: studentData?.email,
                  tasktitle: singleOrder?.order_details?.task?.title,
                  taskMsg: singleOrder?.order_details?.task?.description,
                },
                defaultValue: { Invite: "Invite" },
              },
            })
          }
        >
          <h3 className="profileBoxH3">
            {studentData ? (
              <>
                {studentData.first_name && studentData?.first_name}
                {studentData.last_name && " " + studentData?.last_name.charAt(0).toUpperCase() + "."}
                {studentDateOfBirth}
              </>
            ) : (
              ""
            )}
          </h3>
          <p className="profileBoxP elementCenter">
            {studentData?.student_details?.college || studentData?.college
              ? studentData?.student_details?.college || studentData?.college
              : "No college available"}
          </p>
          <div className="profileBoxStars">
            {/* <ReactStars
              count={5}
              size={25}
              edit={false}
              color2={"#FFC700"}
              color1={"#FFC70040"}
              className="pe-none d-flex justify-content-center"
              value={starRatingValue}
            /> */}

            {studentData?.overall_rating || studentData?.rating?.length > 0 ? (
              <ReactStars
                count={5}
                size={25}
                edit={false}
                color2={"#FFC700"}
                color1={"#FFC70040"}
                className="pe-none d-flex justify-content-center"
                value={
                  studentData?.overall_rating || studentData?.rating?.length > 0
                    ? studentData?.overall_rating || studentData?.rating[0]?.overall_rating
                    : 5
                }
              />
            ) : (
              <div className="marginNeg">
                {/* <img
                  style={{ marginBottom: "6px" }}
                  src="/assets/images/customerDashboard/Star 8.svg"
                  alt=""
                /> */}
                <span className="newStd">New student</span>
              </div>
            )}
          </div>
          <div className="houlyRateValueNew profileBoxStars mb-3">Avg. ${studentMinumunHourlyRate}/hr</div>
        </div>
        <div
          className="profileContent"
          onClick={() =>
            navigate(pathPage, {
              state: {
                query: {
                  taskId: taskId,
                  orderId: orderId,
                  stdFirstName: studentData?.first_name,
                  stdLastName: studentData?.last_name,
                  stdEmail: studentData?.email,
                  tasktitle: singleOrder?.order_details?.task?.title,
                  taskMsg: singleOrder?.order_details?.task?.description,
                },
                defaultValue: { Invite: "Invite" },
              },
            })
          }
        >
          <h4 className="profileBoxSubhed">Featured Tasks</h4>
          <div className="profileTaks flex-wrap">
            {studentData?.skills || studentData?.student_details?.skills ? (
              resultForPrimaryTask
            ) : (
              <>
                <span>None</span>
              </>
            )}
          </div>
        </div>
        <div
          className="profileAbout"
          onClick={() =>
            navigate(pathPage, {
              state: {
                query: {
                  taskId: taskId,
                  orderId: orderId,
                  stdFirstName: studentData?.first_name,
                  stdLastName: studentData?.last_name,
                  stdEmail: studentData?.email,
                  tasktitle: singleOrder?.order_details?.task?.title,
                  taskMsg: singleOrder?.order_details?.task?.description,
                },
                defaultValue: { Invite: "Invite" },
              },
            })
          }
        >
          <h4 className="profileBoxSubhed">About me</h4>
          <p>
            {
              <span className="overflowText line-clamp">
                {studentData?.student_details?.about
                  ? studentData?.student_details?.about
                  : studentData?.about
                  ? studentData?.about
                  : "Not available"}
              </span>
            }
          </p>
        </div>
      </div>
      <div className="profileBtn">
        {btnLoder ? (
          <div className="relative bookBtnNow">
            <div className="loader alignLoader"></div>
            <Link className={classOfInvite} to="">
              Invite
            </Link>
          </div>
        ) : isInvited ? (
          <div className="relative bookBtnNow">
            <Link className="primary bookNowBtn disalbed" to="" style={{ paddingLeft: "20px" }}>
              Invited
            </Link>
          </div>
        ) : (
          <Link className={classOfInvite} to="" onClick={(e) => InviteStudent(studentData.id, studentData.email, e)}>
            Invite
          </Link>
        )}
      </div>
    </div>
  );
}
