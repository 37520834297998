import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getSingleTask } from "../../services/tasks/GetSingleTask";
import Loader from "../loader/Loader";
import { decide } from "../../services/invitestudents/decision/decision";
import { createNotification } from "../../services/notifications/CreateNotification";
import { emailUrl } from "../../utils/constants";
import { sendEmail } from "../../services/contactus/ContactUs";
import { createChatRoom } from "../cutomerdashboard/customerinvitecards/CreatingChatRoom";

const StudentTaskDetail = () => {
  const [loder, setLoder] = useState(true);
  let navigate = useNavigate();
  let { taskId } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isLoggedIn = JSON.parse(localStorage.getItem("sparrowSignIn"));
  const role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;

  const getOrderFunc = async () => {
    const response = await getSingleTask(taskId);

    if (response.status === 200) {
      setOrderData(response.data.task);
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    getOrderFunc();
  }, [loder]);

  const handleConnect = () => {
    // Save current location
    if (!isLoggedIn) {
      sessionStorage.setItem(
        "redirectAfterSignUp",
        `/dashboardRoute/task-history/student-task-descp/${orderData.task_details.id}/${
          orderData?.task_details?.order == null ? orderData?.task_details?.order_draft?.id : orderData?.task_details?.order?.id
        }`
      );
      // Redirect to sign-up
      navigate("/signin");
    } else {
      handleShow();
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    setErrorMessage("");
  };

  const applyForTask = async () => {
    const student_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
    const student_Name =
      JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name.charAt(0).toUpperCase() +
      JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.first_name.slice(1);
    const orderId = orderData?.task_details?.order_draft?.id;
    const student_Email = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.email;

    const data = {
      task_id: orderData?.task_details?.id,
      student_id: student_id,
      message: message ?? null,
    };

    // const resp = await updateOrder(data, orderId);
    const resp = await decide(data);

    if (resp.status === 200) {
      const data = {
        // as reqirement by backend type is 0
        type: 0,
        to_id: orderData?.task_details?.client?.id,
        from_id: student_id,
        data: {
          title: "Task Accepted",
          message: `${student_Name} has applied for your task.`,
          action_url: `/dashboardRoute/customer-notification/task-details/${taskId}?orderId=${orderId}`,
        },
        read_at: null,
      };
      const resp = await createNotification(data);

      const dataForEmail = {
        to: orderData?.task_details?.client?.email,
        subject: `New student application for ${orderData?.task_details?.title}`,
        from: student_Email,
        task_name: orderData?.task_details?.title,
        first_name: orderData?.task_details?.client?.first_name,
        msg: orderData?.task_details?.description,
        action_url: `${emailUrl}/#/dashboardRoute/customer-notification/task-details/${taskId}/?orderId=${orderId}`,
        template: "student_applied_for_task",
        student_name: student_Name,
        message: message != "" || message != null ? message : null,
      };

      await sendEmail(dataForEmail);
      // if (resp.status === 200 && respForEmail.status === 200) {
      if (resp.status === 200) {
        await createChatRoom(student_id, orderData?.task_details?.client?.id, orderData?.task_details?.order_draft?.id);
        setLoder(false);
        Swal.fire({
          title: "Wait for the client to contact you on the platform.",
          showConfirmButton: true,
          customClass: "stModal",
        });
        return;
      }
      setTimeout(() => {
        setLoder(false);
        // window.location.reload();
        navigate("/dashboardRoute/search-tasks/");
      }, 1000);
    }
    setMessage("");
  };

  const handleEmptySendClick = () => {
    setMessage("");
    applyForTask();
    handleClose();
  };

  const handleSendClick = () => {
    if (message.trim() !== "") {
      applyForTask();
      handleClose();
    } else {
      setErrorMessage("Message cannot be empty!");
    }
  };

  const reArrangeArray = (array) => {
    let order = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };
    array.sort(function (a, b) {
      return order[a.day] - order[b.day];
    });
    return array;
  };
  return (
    <div>
      {loder ? (
        <div style={{ height: "100vh" }} className="height100vh">
          <Loader />
        </div>
      ) : (
        <div className="bgNotiLines mb-task-student">
          <Container>
            <>
              <Modal show={show} onHide={handleClose} centered size={"lg"}>
                <Modal.Body>
                  <p
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      margin: "0.5em 0",
                      color: "#333",
                      textAlign: "center",
                    }}
                    className="mb-3"
                  >
                    Send a Message?
                  </p>
                  <p
                    className="mb-4 text-center"
                    style={{
                      fontSize: "1rem",
                      color: "#C7C7CD",
                      textAlign: "center",
                      wordSpacing: 5,
                    }}
                  >
                    A personal introduction increases your chances of getting hired!
                  </p>
                  <textarea
                    placeholder="Write message......"
                    style={{ width: "100%", padding: "1%" }}
                    className="custom-textarea"
                    value={message}
                    onChange={handleInputChange}
                    maxLength={250}
                  />
                  {errorMessage && <p style={{ color: "red", marginTop: "5px" }}>{errorMessage}</p>}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="modalButtonSkip"
                    onClick={() => {
                      handleEmptySendClick();
                    }}
                  >
                    Skip
                  </button>
                  <button
                    className="primary modalButtonSend"
                    onClick={() => {
                      handleSendClick();
                    }}
                  >
                    Send
                  </button>
                </Modal.Footer>
              </Modal>
            </>
            <h2 className="mb57 mb-spac-task">
              Task <span className="green">Description</span>
            </h2>
            <Row>
              <Col md={4}>
                <div className="studentDetails setStduentDtails mb-setstudent-details">
                  <div className="studentDetailsImg mb-3 block">
                    <img src={orderData?.task_details?.client?.image} alt="" />
                  </div>
                  <h5 className="upH5 mb-3 block sprint1Fix">
                    {orderData?.task_details?.client?.first_name?.charAt(0).toUpperCase() + orderData?.task_details?.client?.first_name?.slice(1)}
                    {orderData?.task_details?.client?.last_name && " " + orderData?.task_details?.client?.last_name.charAt(0).toUpperCase()}.
                  </h5>
                  <div className="studentContent ml25 a-studentcontent-mb">
                    <div className="mb-2">
                      <span className="mobileWith25px">
                        <img src="/assets/images/customerDashboard/verified-dashboar.svg" alt="" />
                      </span>
                      &nbsp;&nbsp;
                      <span className="ml-7">{orderData?.task_details?.client?.email_verified_at ? "Verified" : "Not Verified"}</span>
                    </div>
                    <div className="mb-3 block">
                      <span className="mobileWith25px memberIcon">
                        <img src="/assets/images/customerDashboard/Members Icon.svg" alt="" />
                      </span>
                      &nbsp;&nbsp;
                      <span className="ml-7">
                        {orderData?.task_details?.invitedStudents.length > 0
                          ? `${orderData?.task_details?.invitedStudents.length} Invited students`
                          : "0 Invited"}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="studentDescription mb-student-desp wd75">
                  <div className="hourlyPayDiv mb-2 mb-hourlyPayDiv display-none-mb-mobile">
                    <div>
                      <img src="/assets/images/customerDashboard/hourlyvector.svg" alt="" />
                    </div>
                    <div>
                      <span>Hourly Pay</span>
                    </div>
                    <div>
                      <p>${orderData?.task_details?.hourly_rate}</p>
                    </div>
                  </div>
                  <div style={{ margin: "10px 5px" }} className="hourlyPayDiv mb-2 mb-hourlyPayDiv display-none-mb-desktop">
                    <div>
                      <img src="/assets/images/customerDashboard/hourlyvector.svg" alt="" />
                    </div>
                    <div>
                      <span className="heading-mb-stud">Hourly Pay</span>
                    </div>
                    <div>
                      <p>${orderData?.task_details?.hourly_rate}</p>
                    </div>
                  </div>
                  <div className="studentTaskDescpDiv mb-studnt-descp mobilePaddingFix">
                    <Row className="mb-row-padd stdtaskDespSection_mob paddingMobileSet">
                      <Col md={5} className="mb-respon-description">
                        {/* display-none-mb-mobile */}
                        <div className="studentTaskDescpParts ">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/jobtype.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Job Type</p>
                            <h5 style={{ fontFamily: "Poppins" }} className="teaskCtn p-mb-stud">
                              {orderData?.task_details?.type}
                            </h5>
                          </div>
                        </div>
                        <div className="studentTaskDescpParts">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/vectortask.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Task</p>
                            <p style={{ fontFamily: "Poppins" }} className="teaskCtn p-mb-stud">
                              {orderData?.task_details?.title}
                            </p>
                          </div>
                        </div>
                        <div className="studentTaskDescpParts">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/vectorlocation.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Location</p>
                            <p style={{ fontFamily: "Poppins" }} className="teaskCtn p-mb-stud">
                              {orderData?.task_details?.location?.city}, {orderData?.task_details?.location?.state}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={2} className="mb-task-paddding">
                        <div className="line"></div>
                      </Col>
                      <Col md={5} className="mb-respon-descrip mobileWidthFix">
                        <div className="studentTaskDescpParts">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/Vectordate.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Start Date</p>
                            <p className="teaskCtn p-mb-stud">{moment(orderData?.task_details?.start_date).format("MM-DD-YYYY")}</p>
                          </div>
                        </div>

                        <div className="studentTaskDescpParts">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/Vectordate.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">End Date</p>
                            <p className="teaskCtn p-mb-stud">{moment(orderData?.task_details?.end_date).format("MM-DD-YYYY")}</p>
                          </div>
                        </div>
                        {/* )} */}
                        <div className="studentTaskDescpParts ">
                          <div className="stdTaskDespIcon">
                            <img src="/assets/images/customerDashboard/vectortime.svg" alt="" />
                          </div>
                          <div className="ml14 mlMoblie14">
                            <p className="teaskHHed heading-mb-stud">Time</p>
                            <p>
                              {reArrangeArray(orderData?.availability)?.map((item) => {
                                return (
                                  <div>
                                    <div style={{ fontFamily: "Poppins" }} className="paragraph-mb-descrip">
                                      {item?.day.charAt(0).toUpperCase() + item?.day.slice(1)}:
                                    </div>
                                    <div style={{ fontFamily: "Poppins" }} className="paragraph-mb-descrip">
                                      {item.avail[0].start} - {item.avail[0].end}
                                    </div>
                                  </div>
                                );
                              })}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="studentTaskDescpDiv mb-studnt-descp ">
                    <p className="teaskHHed para-mb-stud">Task Description</p>
                    <p className="teaskCtn mt-2">{orderData?.task_details?.description}</p>
                  </div>
                  {/* {!isLoggedIn ? (
                    <>
                      <div className="colorfulBtns display-none-mb-mobile">
                        <>
                          {btnLoder ? (
                            <div className="relative inheritt">
                              <div className="loader alignLoader"></div>
                              <Link to="" className="mainStyle bb3 p-mb-stud">
                                Connect
                              </Link>
                            </div>
                          ) : (
                            <button onClick={handleConnect} className="mainStyle bb3 p-mb-stud">
                              Connect
                            </button>
                          )}
                        </>
                      </div> */}
                  {role != "client" ? (
                    <div className="colorfulBtns">
                      <>
                        <button onClick={handleConnect} className="mainStyle bb3 p-mb-stud">
                          Connect
                        </button>
                      </>
                    </div>
                  ) : null}
                  {/* </>
                  ) : null} */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default StudentTaskDetail;
