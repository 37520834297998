import React from "react";
import ReactDOM from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LocationProvider from "./context/locationProvider";
import ReactPixel from "react-facebook-pixel";
import { localServer, selectedServer } from "./services/axios";

if (selectedServer != localServer) {
  ReactPixel.init("774137003916098");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <LocationProvider>
        <App />
      </LocationProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
