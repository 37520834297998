import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getAllTasks } from "../../services/tasks/AllTasks";
import Swal from "sweetalert2";
import ReactPixel from "react-facebook-pixel";
import Loader from "../loader/Loader";
import AdvancedSearchBarTask from "../advancesearchbar/AdvancedSearchBarTask";
import TaskCard from "../Home/task/TaskCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { localServer, selectedServer } from "../../services/axios";

const FreelancerTaskSearch = () => {
  const [loder, setLoder] = useState(true);
  const [displayedTasks, setDisplayedTasks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [allTasks, setAllTasks] = useState([]);
  const [searchedValue, setSearchedValue] = useState();

  const getAllTaskFunc = async () => {
    const response = await getAllTasks();
    if (response.status === 200) {
      setAllTasks(response?.data?.data);
      setDisplayedTasks(response?.data?.data.slice(0, 12));
      setHasMore(response?.data?.data.length > 12); // Check for more tasks
      setLoder(false);
    } else {
      Swal.fire({
        title: response.data.message,
        timer: 1500,
        icon: "error",
        showConfirmButton: false,
      });
      setLoder(false);
    }
  };

  useEffect(() => {
    getAllTaskFunc();
    if (selectedServer != localServer) {
      ReactPixel.track("All Students");
    }
  }, []);

  const handleViewStudent = (studentArray) => {
    console.log("got my search results: ", studentArray);
    setAllTasks(studentArray);
    setDisplayedTasks(studentArray?.slice(0, 12));
    setHasMore(studentArray?.length > 12); // Reset hasMore based on new data
  };

  const fetch12MoreStudents = () => {
    const nextTasks = allTasks.slice(displayedTasks.length, displayedTasks.length + 12);
    setDisplayedTasks([...displayedTasks, ...nextTasks]);
    if (displayedTasks.length + nextTasks.length >= allTasks.length) {
      setHasMore(false); // No more data to fetch
    }
  };

  return (
    <>
      {loder ? (
        <div className="height100vh height10vh">
          <Loader />
        </div>
      ) : (
        <div className="viewAllStudents">
          <Container className="text-center pl90">
            <h2 className="mt70">
              Search <span className="green">Tasks</span>
            </h2>
            <AdvancedSearchBarTask searchedValue={setSearchedValue} updateStudentResult={(studentArray) => handleViewStudent(studentArray)} />
            <InfiniteScroll dataLength={displayedTasks?.length} next={fetch12MoreStudents} hasMore={hasMore} style={{ overflow: "none" }}>
              <Row className="viewAllStCards">
                {displayedTasks?.map((singleTask) => {
                  return (
                    <Col md={4} className="mb-5" key={singleTask?.id}>
                      <TaskCard taskData={singleTask} />
                    </Col>
                  );
                })}
              </Row>
            </InfiniteScroll>
          </Container>
        </div>
      )}
    </>
  );
};

export default FreelancerTaskSearch;
