import axios from "axios";

export const localServer = "http://localhost/sparrow-backend/api";
export const liveServer = "https://apis.findsparrow.com/api";
export const stagingServer = "https://stagingapis.findsparrow.com/api";
export const selectedServer = liveServer;

// guestAxios used only in login component
export const guestAxios = axios.create({
  baseURL: selectedServer,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

// instance used allover the site as baseUrl
const instance = axios.create({
  baseURL: selectedServer,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

export const bucket_url =
  selectedServer == liveServer
    ? "https://findsparrow-app-bucket.s3.us-west-1.amazonaws.com/images"
    : "https://findsparrow-staging-bucket.s3.us-west-1.amazonaws.com/images";
export default instance;
