import React, { useEffect } from "react";
import HomeBanner from "./HomeBanner";
import HomeStudentProfile from "./HomeStudentProfile";
import HomeTasks from "./HomeTasks";
import HomeSupportStudent from "./HomeSupportStudent";
import HomeHireStudent from "./HomeHireStudent";
import HomeHappyUsers from "./HomeHappyUsers";
import HomeFindStudent from "./HomeFindStudent";
import HomeFingerPrintsLatest from "./HomeFingerPrintsLatest";
import ContactUsIcon from "../contactus/ContactUsIcon";
import RealCollege from "./RealCollege";
import ReactPixel from "react-facebook-pixel";
import { localServer, selectedServer } from "../../services/axios";

function HomeMain() {
  useEffect(() => {
    if (selectedServer != localServer) {
      ReactPixel.track("Student Customer Home Page");
    }
  }, []);
  return (
    <div>
      <ContactUsIcon />
      <HomeBanner />
      <HomeStudentProfile />
      <HomeTasks />
      <RealCollege />
      <HomeSupportStudent />
      <HomeHireStudent />
      <HomeFingerPrintsLatest />
      <HomeHappyUsers />
      <HomeFindStudent />
    </div>
  );
}

export default HomeMain;
