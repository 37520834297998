import React, { useEffect } from "react";
import ContactUsIcon from "../contactus/ContactUsIcon";
import FreelanceLastSecResp from "./FreelanceLastSecResp";
import FreelancerBanner from "./FreelancerBanner";
import FreelancerChoose from "./FreelancerChoose";
import FreelancerMoney from "./FreelancerMoney";
import FreelancerNo from "./FreelancerNo";
import FreelancerSignup from "./FreelancerSignup";
import FreelancerSimple from "./FreelancerSimple";
import FreelancerSupportStudent from "./FreelancerSupportStudent";
import ReactPixel from "react-facebook-pixel";
import FreelancerTaskHome from "./FreelancerTaskHome";
import { localServer, selectedServer } from "../../services/axios";

const FreelancerMain = () => {
  useEffect(() => {
    if (selectedServer != localServer) {
      ReactPixel.track("Student Stats");
    }
  }, []);
  return (
    <div className="freelanceMain">
      <ContactUsIcon />
      <FreelancerBanner />
      <FreelancerTaskHome />
      <FreelancerChoose />
      <FreelancerSimple />
      <FreelancerSupportStudent />
      <FreelancerNo />
      <FreelancerSignup />
      <FreelancerMoney />
      <FreelanceLastSecResp />
    </div>
  );
};

export default FreelancerMain;
