import React, { useState } from "react";
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteStudent } from "../../services/savestudents/DeleteStudent";
import { getAllSaveStudents } from "../../services/savestudents/GetAllStudents";
import { saveStudent } from "../../services/savestudents/SaveStudent";
import { ShowAllStudents } from "../../services/users/ShowAllStudents";
import ReactStars from "react-stars";

export default function StudentProfileCard({ searchedValue, bookNow, studentData, setAllStudent }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams(); // Use useSearchParams to get current query parameters

  const [isSaved, setIsSaved] = useState(() => {
    // Determine if the student is saved initially
    return !!studentData?.student_saved_by?.length || !!studentData?.student_saved_id;
  });

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  // defing userrole and user id
  let user_role = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role;
  let user_id = JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id;
  // delete student
  const action_DeleteFav = async (std_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      if (user_role === "client") {
        const resp = await deleteStudent(std_id);
        setIsSaved(false); // Mark as saved
        await ShowAllStudents(2, JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id);

        if (resp.status === 200) {
          setIsSaved(false); // Mark as saved
        } else {
          Swal.fire({
            title: resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
          setIsSaved(true); // Mark as saved
        }
      } else {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };

  let countPrimaryTask = 0;
  let terminateLoop = false;

  // papulate about variable from home page featured api aur student api

  let imageUrl = "/assets/images/Groupa21765.png";
  if (studentData?.image) {
    imageUrl = studentData?.image;
  }

  let studentMinumunHourlyRate = 0;
  if (studentData?.student_details?.minimum_hourly_rate) {
    studentMinumunHourlyRate = studentData?.student_details?.minimum_hourly_rate;
  } else if (studentData?.minimum_hourly_rate) {
    studentMinumunHourlyRate = studentData?.minimum_hourly_rate;
  }

  let studentDateOfBirth = studentData?.student_details?.dob
    ? studentData?.student_details?.dob && " " + getAge(studentData?.student_details?.dob) + "'"
    : studentData?.dob && " " + getAge(studentData?.dob) + "'";

  let pathPage = {
    pathname: `/signInRoute/all-students/single-student-profile/${studentData.id}`,
    search: `?${searchParams.toString()}`, // Use searchParams.toString()
  };

  if (localStorage.getItem("sparrowSignIn")) {
    pathPage = {
      pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`,
      search: `?${searchParams.toString()}`, // Use searchParams.toString()
    };
  }

  let skillValue = studentData?.student_details?.skills ? studentData?.student_details?.skills : studentData?.skills;

  function extractSkillsFromUrl(url) {
    // Extract the query string part after the `?`
    const queryString = url.split("?")[1];

    if (queryString) {
      // Parse the query string
      const params = new URLSearchParams(queryString);

      // Get the `skills` parameter
      const skills = params.get("skills");

      return skills; // Returns the value of `skills`, or null if not present
    }

    return null; // Return null if there is no query string
  }

  const skills = extractSkillsFromUrl(window.location.href);

  let resultForPrimaryTask;
  if (skills == null) {
    resultForPrimaryTask =
      skillValue?.length > 0
        ? skillValue
            .split(/\s*,\s*/)
            .sort((a, b) => {
              // Sorting logic remains the same
              const containsA = a.toLowerCase().includes(searchedValue?.toLowerCase());
              const containsB = b.toLowerCase().includes(searchedValue?.toLowerCase());

              if (containsA && !containsB) {
                return -1;
              } else if (containsB && !containsA) {
                return 1;
              } else {
                return a.length - b.length;
              }
            })
            .map((item, index) => {
              if (!terminateLoop) {
                let displayItem = item;

                // Replace "|" with " | "
                if (displayItem.includes("|")) {
                  displayItem = displayItem.replace(/\|/g, " | ");
                }

                // Adjust displayItem if it's too long
                if (displayItem.length > 32) {
                  if (displayItem.toLowerCase().includes("Sports Coaching")) {
                    displayItem = "Coaching";
                  } else if (displayItem.toLowerCase().includes("tutoring")) {
                    displayItem = "Tutoring";
                  }
                } else {
                  if (displayItem.toLowerCase().includes("sports coaching")) {
                    displayItem = displayItem.replace("Sports Coaching:", "Coaching: ");
                  }
                }

                // Now, calculate the length
                const itemLength = displayItem.length;

                // Check if adding this item will exceed 28 characters
                if (countPrimaryTask + itemLength <= 28 && index < 4) {
                  countPrimaryTask += itemLength;
                  return <span key={index}>{displayItem.trim()}</span>;
                } else {
                  terminateLoop = true;
                  return (
                    <Link
                      to={{
                        pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`,
                        search: location.search, // Preserve current searchParams
                      }}
                      role="button"
                      key={index}
                    >
                      <img src="/assets/images/customerDashboard/ActionsPlus_bold.svg" className="imageForMoreTask" alt="" />
                    </Link>
                  );
                }
              }
              return null;
            })
        : "";
  } else {
    resultForPrimaryTask =
      skillValue?.length > 0 && skills
        ? skillValue
            .split(/\s*,\s*/) // Split the skills into individual parts
            .filter((skill) => {
              if (skills) {
                // If skills exist in parsedstoredDataJSON, match against it
                const storedSkill = skills.toLowerCase();
                return skill?.toLowerCase()?.includes(storedSkill);
              } else {
                // If skills are null, just ensure skill is a valid string
                return skill?.toLowerCase();
              }
            })
            .sort((a, b) => {
              // Define a custom sorting function based on the presence of searchedValue
              const containsA = a?.toLowerCase()?.includes(searchedValue?.toLowerCase());
              const containsB = b?.toLowerCase()?.includes(searchedValue?.toLowerCase());

              if (containsA && !containsB) {
                return -1; // a should come before b
              } else if (containsB && !containsA) {
                return 1; // b should come before a
              } else {
                return a.length - b.length;
              }
            })
            .map((item, index) => {
              if (!terminateLoop) {
                let displayItem = item;

                if (displayItem.toLowerCase().includes("tutoring:")) {
                  displayItem = displayItem.replace("Tutoring:", "");
                }

                if (displayItem.toLowerCase().includes("sports coaching")) {
                  displayItem = displayItem.replace("Sports Coaching:", "");
                }

                if (displayItem.includes("|")) {
                  displayItem = displayItem.replace(/\|/g, " | ");
                }

                // Count character length and limit display
                if (countPrimaryTask <= 28 && index < 4) {
                  return <span key={index}>{displayItem.trim()}</span>;
                } else {
                  terminateLoop = true;
                  return (
                    <Link
                      to={{
                        pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`,
                        search: location.search, // Preserve current searchParams
                      }}
                      role="button"
                      key={index}
                    >
                      <img src="/assets/images/customerDashboard/ActionsPlus_bold.svg" className="imageForMoreTask" alt="" />
                    </Link>
                  );
                }
              }
              return null;
            })
        : "";
  }

  // saving students
  const action_AddToFav = async (std_id) => {
    if (JSON.parse(localStorage.getItem("sparrowSignIn"))?.user) {
      if (user_role === "client") {
        const data = { client_id: user_id, student_id: std_id };
        const resp = await saveStudent(data);
        setIsSaved(true); // Mark as saved
        await ShowAllStudents(2, JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.id);

        if (resp.status === 200) {
          setIsSaved(true); // Mark as saved
        } else {
          Swal.fire({
            title: resp.data.message,
            timer: 1500,
            icon: "error",
            showConfirmButton: false,
          });
          setIsSaved(false); // Mark as saved
        }
      } else {
        Swal.fire({
          title: "Please log in as a client",
          timer: 1500,
          icon: "error",
          showConfirmButton: false,
        });
      }
    } else {
      setTimeout(() => {
        navigate("/signin");
      }, 0);
    }
  };

  // const handleImageError = (e) => {
  //   e.target.src = '/assets/images/dummy12.jpeg'; // Set the src attribute to your default image URL
  //   e.target.alt = 'Default Image'; // Optional: Change the alt text for the default image
  // };

  const handleConnect = () => {
    // Save current location
    if (JSON.parse(localStorage.getItem("sparrowSignIn")) === null) {
      sessionStorage.setItem("redirectAfterSignUp", `/dashboardRoute/all-students/customer-student-profile/${studentData.id}`);
    }
  };

  return (
    <div className="profileBox border-mb-fields profileBoxForStudent">
      <div className="profileContainer">
        <div className="profileImg">
          <img className="profileImgM imgBorder" src={imageUrl} alt="" />
          {studentData?.totalOrders > 50 ? (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/goldA.svg" alt="" />
              <span className="hoverText setResonive">This student has done over 100 tasks</span>
            </div>
          ) : studentData?.totalOrders > 25 && studentData?.totalOrders <= 50 ? (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/silverb.svg" alt="" />
              <span className="hoverText setResonive">This student has done 25-50 tasks</span>
            </div>
          ) : (
            <div className="hoverTextDiv">
              <img className="badgeStdnt" src="/assets/images/badges/bronzec.svg" alt="" />
              <span className="hoverText setResonive">This student has done 0-25 tasks</span>
            </div>
          )}
          {/* student_saved_id coming from customermyStudent component and is for checking student is saved so show goldenRomal img  || none is coming from HomeStudentProfile component || 3 in function and isSaved is for featured student from home page*/}
          <div className={`hoverTextDiv`}>
            {isSaved || studentData?.student_saved_by?.length > 0 || studentData?.student_saved_id ? (
              // Render "Remove from saved students"
              <div style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => action_DeleteFav(studentData?.id)}>
                <img src="/assets/images/home/goldenlabelicon.png" alt="Remove from saved students" className="romaalimg favClassLabel" />
                <span className="hoverText setResonive">Remove from saved students</span>
              </div>
            ) : (
              // Render "Add to favorite students"
              <div style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => action_AddToFav(studentData?.id)}>
                <img src="/assets/images/home/greynlabelicon.png" alt="Add to favorite students" className="romaalimg" />
                <span className="hoverText setResonive">Add to favorite students</span>
              </div>
            )}
          </div>
        </div>
        <div className="profilHed profileBoxStars mb-3" onClick={() => navigate(pathPage)}>
          <h3 className="profileBoxH3">
            {studentData ? (
              <>
                {studentData.first_name && studentData?.first_name}
                {studentData.last_name && " " + studentData?.last_name.charAt(0).toUpperCase()}
                {studentDateOfBirth}
              </>
            ) : (
              ""
            )}
          </h3>
          <p className="profileBoxP elementCenter">
            {/* <span><img src="/assets/images/home/cap.svg" alt="" /></span> */}
            {studentData?.student_details?.college || studentData?.college
              ? studentData?.student_details?.college || studentData?.college
              : "No college available"}
          </p>
          <div className="profileBoxStars">
            {studentData?.overall_rating || studentData?.rating?.length > 0 ? (
              <ReactStars
                count={5}
                size={25}
                edit={false}
                color2={"#FFC700"}
                color1={"#FFC70040"}
                className="pe-none d-flex justify-content-center"
                value={
                  studentData?.overall_rating || studentData?.rating?.length > 0
                    ? studentData?.overall_rating || studentData?.rating[0]?.overall_rating
                    : 5
                }
              />
            ) : (
              <div className="marginNeg">
                {/* <img
                  style={{ marginBottom: "6px" }}
                  // src="/assets/images/customerDashboard/dullStarForAdvSearch.svg"
                  src="/assets/images/customerDashboard/Star 8.svg"
                  alt=""
                /> */}
                <span className="newStd">New student</span>
              </div>
            )}
          </div>
          <div className="houlyRateValueNew profileBoxStars">Avg. ${studentMinumunHourlyRate}/hr</div>
        </div>
        <div className="profileContent" onClick={() => navigate(pathPage)}>
          <h4 className="profileBoxSubhed">Featured Tasks</h4>
          <div className="profileTaks flex-wrap">
            {studentData?.skills || studentData?.student_details?.skills ? (
              resultForPrimaryTask
            ) : (
              <>
                <span>None</span>
              </>
            )}
          </div>
        </div>
        <div className="profileAbout" onClick={() => navigate(pathPage)}>
          <h4 className="profileBoxSubhed">About me</h4>
          <p>
            {
              <span className="overflowText line-clamp">
                {studentData?.student_details?.about || studentData?.about
                  ? studentData?.student_details?.about || studentData?.about
                  : "Not available"}
              </span>
            }
            {/* {studentData && (studentData?.student_details?.about?.length > 130 || studentData?.about?.length > 130) ? <Link to={{ pathname: `/dashboardRoute/all-students/customer-student-profile/${studentData.id}` }} className="green stdcardViewMore"> View More</Link>: null }  */}
          </p>
        </div>
      </div>
      <div className="profileBtn">
        <Link
          to={
            JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role === "client"
              ? `/dashboardRoute/all-students/create-tasks/${studentData?.id}/${studentData?.fcm_token}`
              : JSON.parse(localStorage.getItem("sparrowSignIn"))?.user?.role === "student"
              ? "/dashboardRoute/student-profile/"
              : "/signin"
          }
          className="primary bookNowBtn"
          onClick={handleConnect}
        >
          {bookNow}
        </Link>
      </div>
    </div>
  );
}
