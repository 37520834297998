import React, { useEffect } from "react";
import ContactUsIcon from "../contactus/ContactUsIcon";
import AboutBanner from "./AboutBanner";
import AboutConnecting from "./AboutConnecting";
import AboutExpanding from "./AboutExpanding";
import AboutFaq from "./AboutFaq";
import AboutInspiration from "./AboutInspiration";
import ReactPixel from "react-facebook-pixel";
import { localServer, selectedServer } from "../../services/axios";

function AboutMain() {
  useEffect(() => {
    if (selectedServer != localServer) {
      ReactPixel.track("FAQ page");
    }
  }, []);
  return (
    <div>
      <ContactUsIcon />
      <AboutBanner />
      <AboutConnecting />
      <AboutInspiration />
      <AboutFaq />
      <AboutExpanding />
    </div>
  );
}

export default AboutMain;
